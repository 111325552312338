<script type="text/javascript">
  jQuery(function ($) {
    //Scope Selector dropdown
    $('.scope-selector-toggle span').click(function () { $('.scope-selector').toggleClass('open'); });
    debugger;
    $(document).add('.apply').bind('click', function (e) {
      var parentElement = $('.scope-selector')[0];
      if (!$.contains(parentElement, e.target) || !parentElement == e.target) {
        $('.scope-selector.open').removeClass('open');
      }
    });

    //Scope Selector menu
    $('.ss-menu-wrap li span').click(function () {
      $(this).parent('li').toggleClass('open').siblings('li').removeClass('open').find('li').removeClass('open');
      $(this).parent('li').find('li').removeClass('open');
      $('.ss-menu-wrap li').removeClass('selected');
      $('.ss-menu-wrap li.open').last().addClass('selected');
    });
  });
</script>
<!-- header -->
<header class="myw-app-header myw-container d-flex">
  <div class="myw-brand d-flex align-items-end">
    <img src="../assets/images/logo.svg" alt="accenture" />
    <span><img src="../assets/images/mywiz.svg" alt="myWizard"></span>
  </div>
  <div class="myw-header-nav d-flex justify-content-end">
    <!-- scope selector -->
    <!-- scope selector -->
    <div class="myw-scope dropdown d-flex show" style="margin: 0px 48px">
      <div (click)="toggleScopeSelector()" style="margin-top: 15px;width: auto;height: 16px; font-size: 12px;"
        title="{{this.scopeDisplayTitle}}">
        <a href="javascript:;" class="myw-header-item dropdown-toggle" role="button" id="scopeSelector">
          <span></span>
         <span style="display:contents;">{{this.scopeDisplayTitleLimit}}</span>
          <img src="../assets/images/scope.svg" alt="" class="d-lg-none" />
        </a>
      </div>
      <div class="dropdown-menu {{showScopeSelector}}" (clickOutside)="closeScopeSelector(clickedonheadervalues)"
        style="width: 500px; max-height: 400px;margin-left: -70px;">
        <form class="px-4 py-3">
          <div class="form-group">
            <label class="form-label">Client</label>
            <select class="form-control control-border rounded" [(ngModel)]="selectedClientId"
              [ngModelOptions]="{standalone: true}">
              <option *ngFor="let client of clientList" value="{{client.Id}}">
                {{client.Name}}
              </option>
            </select>
          </div>
          <!-- <div class="form-group">
            <label class="form-label">CMS</label>
            <select class="form-control control-border rounded">
              <option>Select</option>
              <option>CMS1</option>
              <option>CMS2</option>
            </select>
          </div>
          <div class="form-group">
            <label class="form-label">WS</label>
            <select class="form-control control-border rounded">
              <option>Select</option>
              <option>WS1</option>
              <option>WS2</option>
            </select>
          </div> -->

          <!-- <div>
            
            <span class="ss-initiative" style="margin: 2%;"><img src="../assets/images/ss_initiative.png" alt="Initiative" style="height: 30px;"  /></span>
            <span style="font-size: 13px;">{{selecteEndtoEndName}}</span>
          </div> -->

          <!-- old AJ code for scope selector -->

          <div class="ss-menu-wrap">
            <ul>
              <li *ngFor="let cioGroup of contractOpportunityInitiativeGroups"
                [ngClass]="{'open' : cioGroup.IsChildCollapsed == false}"
                (click)="SelectEndToEnd($event, cioGroup, null); " id="contractOpportunityInitiative">
                <span *ngIf="cioGroup.IsGroup" class="ss-folder endToEnd" (click)="setLatestSelectedCMSorWS('','','','','endToEnd');checke2eclass();" id="e2e1" >
                  <span>
                    {{cioGroup.GroupName}}
                  </span>
                </span>

                <span *ngIf="cioGroup.EndToEndType == 'O'" class="ss-opportunity endToEnd" (click)="setLatestSelectedCMSorWS('','','','','endToEnd');checke2eclass1();"
                  [ngClass]="{ 'ss-sub' : cioGroup.CMSLevelOne.length > 0}" id="e2e2">
                  <!-- <span *ngIf="cioGroup.EndToEndRefCode!=''">
                    {{cioGroup.EndToEndRefCode}} : {{cioGroup.EndToEndName}}
                  </span> -->
                  <span  (click)="setLatestSelectedCMSorWS('','','','','endToEnd')">
                    <span class="ss-initiative" style="margin: 2%;left: -40px;"><img src="../assets/images/ss_initiative.png"
                        alt="Initiative" style="height: 30px;" /></span>
                    {{cioGroup.EndToEndName}}
                  </span>
                </span>

                <span *ngIf="cioGroup.EndToEndType == 'C'" class="ss-contract endToEnd" (click)="setLatestSelectedCMSorWS('','','','','endToEnd');checke2eclass2();" 
                  [ngClass]="{'ss-sub' : cioGroup.CMSLevelOne.length > 0}" id="e2e3">
                  <!-- <span *ngIf="cioGroup.EndToEndRefCode!=''">
                    {{cioGroup.EndToEndRefCode}} : {{cioGroup.EndToEndName}}
                  </span> -->
                  <span  (click)="setLatestSelectedCMSorWS('','','','','endToEnd')">
                    <span class="ss-initiative endToEnd" style="margin: 2%;"><img src="../assets/images/ss_initiative.png"
                        alt="Initiative" style="height: 30px;margin-left: -50px;" /></span>
                    {{cioGroup.EndToEndName}}
                  </span>
                </span>

                <span *ngIf="cioGroup.EndToEndType == 'I'" class="endToEnd" (click)="setLatestSelectedCMSorWS('','','','','endToEnd');checke2eclass3();" 
                  [ngClass]="{'ss-sub' : (cioGroup.CMSLevelOne.length > 0)}" id="e2e4">
                  <!-- <span *ngIf="cioGroup.EndToEndRefCode!=''">
                    {{cioGroup.EndToEndRefCode}} : {{cioGroup.EndToEndName}}
                  </span> -->
                  <span  (click)="setLatestSelectedCMSorWS('','','','','endToEnd')">
                    <span class="ss-initiative" style="margin-left:-50px;"><img src="../assets/images/ss_initiative.png"
                        alt="Initiative" style="height: 30px;" /></span>
                    {{cioGroup.EndToEndName}}
                  </span>
                </span>

                <div *ngIf="cioGroup.IsGroup">
                  <ul>
                    <li *ngFor=" let cio of cioGroup.ContractOpportunityInitiatives"
                      (click)="SelectEndToEnd($event, cio, cioGroup); "
                      [ngClass]="{open : cio.IsChildCollapsed == false}">
                      <span *ngIf="cio.EndToEndType == 'O'" class="ss-opportunity" (click)="setLatestSelectedCMSorWS('','','','','endToEnd');checkcontract1();"
                        [ngClass]="{'ss-sub' : cio.CMSLevelOne.length > 0}" id="contract1">
                        <span>
                          {{ cio.Type }} - {{cio.EndToEndRefCode}} : {{cio.EndToEndName}}
                        </span>
                      </span>

                      <span *ngIf="cio.EndToEndType == 'C'" class="ss-contract" (click)="setLatestSelectedCMSorWS('','','','','endToEnd');checkcontract2();"
                        [ngClass]="{'ss-sub' : cio.CMSLevelOne.length > 0}" id="contract2">
                        <span>{{ cio.Type }} - {{cio.EndToEndRefCode}} : {{cio.EndToEndName}}</span>
                      </span>

                      <span *ngIf="cio.EndToEndType == 'I'" class="ss-initiative" (click)="setLatestSelectedCMSorWS('','','','','endToEnd');checkcontract3();"
                        [ngClass]="{'ss-sub' : cio.CMSLevelOne.length > 0}" id="contract3">
                        <span>{{ cio.Type }} - {{cio.EndToEndRefCode}} : {{cio.EndToEndName}}</span>
                      </span>
                    </li>
                  </ul>
                </div>

                <div *ngIf="!cioGroup.IsGroup">
                  <ul>
                    <li *ngFor="let ws of cioGroup.StandaloneWorkstreams"
                      (click)="SelectWorkstream(ws, null, cioGroup);">
                      <span id="{{'ws0' + ws.WorkstreamUId}}" (click)="checkWS0Class(ws.WorkstreamUId)"><span (click)="setLatestSelectedCMSorWS('ws', ws.WorkstreamUId, '' , '', 'endToEnd')"
                          [ngClass]="{'ss-ws-ad':ws.WorkstreamTypeId==1, 'ss-ws-agile':(ws.WorkstreamTypeId==3 || ws.WorkstreamTypeId==4 || ws.WorkstreamTypeId==5 || ws.WorkstreamTypeId==6 || ws.WorkstreamTypeId==7 || ws.WorkstreamTypeId==8 || ws.WorkstreamTypeId==9),'ss-ws-am':ws.WorkstreamTypeId==2,'workstream-readonly' : IsWorkStreamSelectable}">
                          {{ ws.Workstream }}</span></span>
                    </li>
                    <li *ngFor="let cms1 of cioGroup.CMSLevelOne" (click)="SelectCMS($event,null, cioGroup, cms1);"
                      (click)="$event.stopPropagation()"
                      [ngClass]="{open : cms1.IsChildCollapsed == false,'ss-exp-col' : ((cms1.CMSLevelTwo).length > 0 || cms1.Workstreams.length>0) }">
                      <span  id="{{'cms1' + cms1.CMSUId}}" (click)="checkCms1Class(cms1.CMSUId)">
                        <span [ngClass]="{'default-cursor' : !IsCMSSelectable}" (click)="setLatestSelectedCMSorWS('cms', cms1.CMSUId, '' , cms1.Id, 'endToEnd')"> {{ cms1.Name }}</span>
                      </span>
                      <div>
                        <ul>
                          <li *ngFor="let ws of cms1.Workstreams" (click)="SelectWorkstream(ws, null, cioGroup, cms1);"
                            (click)="$event.stopPropagation()">
                            <span id="{{'ws1' + ws.WorkstreamUId}}" (click)="checkWS1Class(ws.WorkstreamUId)"><span (click)="setLatestSelectedCMSorWS('ws', cms1.CMSUId, ws.WorkstreamUId, ws.WorkstreamId, 'endToEnd')"
                                [ngClass]="{'ss-ws-ad':ws.WorkstreamTypeId==1,'ss-ws-agile':(ws.WorkstreamTypeId==3 || ws.WorkstreamTypeId==4 || ws.WorkstreamTypeId==5 || ws.WorkstreamTypeId==6 || ws.WorkstreamTypeId==7 || ws.WorkstreamTypeId==8 || ws.WorkstreamTypeId==9),'ss-ws-am':ws.WorkstreamTypeId==2,'workstream-readonly' : IsWorkStreamSelectable}">
                                {{ ws.Workstream }}</span></span>
                          </li>
                          <li *ngFor="let cms2 of cms1.CMSLevelTwo"
                            (click)="SelectCMS($event,null, cioGroup, cms1,cms2);" (click)="$event.stopPropagation()"
                            [ngClass]="{open : cms2.IsChildCollapsed == false,'ss-exp-col' : ((cms2.CMSLevelThree).length > 0 || cms2.Workstreams.length>0)}">
                            <span id="{{'cms2' + cms2.CMSUId}}" (click)="checkCms2Class(cms2.CMSUId)"><span (click)="setLatestSelectedCMSorWS('cms', cms2.CMSUId, '', cms2.Id, 'endToEnd')" [ngClass]="{'default-cursor' : !IsCMSSelectable}"> {{ cms2.Name }}</span></span>
                            <div>
                              <ul>
                                <li *ngFor="let ws of cms2.Workstreams"
                                  (click)="SelectWorkstream(ws, null, cioGroup, cms1,cms2);"
                                  (click)="$event.stopPropagation()">
                                  <span   id="{{'ws2' + ws.WorkstreamUId}}" (click)="checkWS2Class(ws.WorkstreamUId)"><span (click)="setLatestSelectedCMSorWS('ws', cms2.CMSUId,ws.WorkstreamUId, ws.WorkstreamId, 'endToEnd')"
                                      [ngClass]="{'ss-ws-ad':ws.WorkstreamTypeId==1,'ss-ws-agile':(ws.WorkstreamTypeId==3 || ws.WorkstreamTypeId==4 || ws.WorkstreamTypeId==5 || ws.WorkstreamTypeId==6 || ws.WorkstreamTypeId==7 || ws.WorkstreamTypeId==8 || ws.WorkstreamTypeId==9),'ss-ws-am':ws.WorkstreamTypeId==2,'workstream-readonly' : IsWorkStreamSelectable}">
                                      {{ ws.Workstream }}</span></span>
                                </li>
                                <li *ngFor="let cms3 of cms2.CMSLevelThree"
                                  (click)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3);"
                                  (click)="$event.stopPropagation()"
                                  [ngClass]="{open : cms3.IsChildCollapsed == false,'ss-exp-col' : ((cms3.CMSLevelFour).length > 0 || cms3.Workstreams.length>0)}">
                                  <span   id="{{'cms3' + cms3.CMSUId}}" (click)="checkCms3Class(cms3.CMSUId)"><span (click)="setLatestSelectedCMSorWS('cms', cms3.CMSUId,'', cms3.Id, 'endToEnd')"
                                      [ngClass]="{'default-cursor' : !IsCMSSelectable}"> {{ cms3.Name }}</span></span>
                                  <div>
                                    <ul>
                                      <li *ngFor="let ws of cms3.Workstreams"
                                        (click)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3);"
                                        (click)="$event.stopPropagation()">
                                        <span  id="{{'ws3' + ws.WorkstreamUId}}" (click)="checkWS3Class(ws.WorkstreamUId)"><span (click)="setLatestSelectedCMSorWS('ws', cms3.CMSUId, ws.WorkstreamUId, ws.WorkstreamId, 'endToEnd')"
                                            [ngClass]="{'ss-ws-ad':ws.WorkstreamTypeId==1,'ss-ws-agile':(ws.WorkstreamTypeId==3 || ws.WorkstreamTypeId==4 || ws.WorkstreamTypeId==5 || ws.WorkstreamTypeId==6 || ws.WorkstreamTypeId==7 || ws.WorkstreamTypeId==8 || ws.WorkstreamTypeId==9),'ss-ws-am':ws.WorkstreamTypeId==2,'workstream-readonly' : IsWorkStreamSelectable}">
                                            {{ ws.Workstream }}</span></span>
                                      </li>
                                      <!-- <li *ngFor="let cms4 of cms3.CMSLevelFour"
                                        (click)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3, cms4);"
                                        (click)="$event.stopPropagation()"
                                        [ngClass]="{open : cms4.IsChildCollapsed == false,'ss-exp-col' : ((cms4.CMSLevelFive).length > 0 || cms4.Workstreams.length>0)}">
                                        <span   id="{{'cms4' + cms4.CMSUId}}" (click)="checkCms4Class(cms4.CMSUId)"><span (click)="setLatestSelectedCMSorWS('cms', cms4.CMSUId,'', cms4.Id, 'endToEnd')"
                                            [ngClass]="{'default-cursor' : !IsCMSSelectable}"> {{ cms4.Name
                                            }}</span></span>

                                        <div>
                                          <ul>
                                            <li *ngFor="let ws of cms4.Workstreams"
                                              (click)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3, cms4);"
                                              (click)="$event.stopPropagation()">
                                              <span  id="{{'ws4' + ws.WorkstreamUId}}" (click)="checkWS4Class(ws.WorkstreamUId)"><span (click)="setLatestSelectedCMSorWS('ws',cms4.CMSUId, ws.WorkstreamUId, ws.WorkstreamId, 'endToEnd')"
                                                  [ngClass]="{'ss-ws-ad':ws.WorkstreamTypeId==1,'ss-ws-agile':(ws.WorkstreamTypeId==3 || ws.WorkstreamTypeId==4 || ws.WorkstreamTypeId==5 || ws.WorkstreamTypeId==6 || ws.WorkstreamTypeId==7 || ws.WorkstreamTypeId==8 || ws.WorkstreamTypeId==9),'ss-ws-am':ws.WorkstreamTypeId==2,'workstream-readonly' : IsWorkStreamSelectable}">
                                                  {{ ws.Workstream }}</span></span>
                                            </li>
                                            <li *ngFor="let cms5 of cms4.CMSLevelFive"
                                              (click)="SelectCMS($event,null, cioGroup, cms1, cms2, cms3, cms4, cms5);"
                                              (click)="$event.stopPropagation()"
                                              [ngClass]="{open : cms5.IsChildCollapsed == false,'ss-exp-col' : cms5.Workstreams.length>0}">
                                              <span  id="{{'cms5' + cms5.CMSUId}}" (click)="checkCms5Class(cms5.CMSUId)"><span (click)="setLatestSelectedCMSorWS('cms', cms5.CMSUId,'', cms5.Id, 'endToEnd')"
                                                  [ngClass]="{'default-cursor' : !IsCMSSelectable}"> {{ cms5.Name
                                                  }}</span></span>
                                              <div>
                                                <ul>
                                                  <li *ngFor="let ws of cms5.Workstreams"
                                                    (click)="SelectWorkstream(ws, null, cioGroup, cms1, cms2, cms3, cms4, cms5);"
                                                    (click)="$event.stopPropagation()">
                                                    <span  id="{{'ws5' + ws.WorkstreamUId}}" (click)="checkWS5Class(ws.WorkstreamUId)"><span (click)="setLatestSelectedCMSorWS('ws',cms5.CMSUId, ws.WorkstreamUId, ws.WorkstreamId, 'endToEnd')"
                                                        [ngClass]="{'ss-ws-ad':ws.WorkstreamTypeId==1,'ss-ws-agile':(ws.WorkstreamTypeId==3 || ws.WorkstreamTypeId==4 || ws.WorkstreamTypeId==5 || ws.WorkstreamTypeId==6 || ws.WorkstreamTypeId==7 || ws.WorkstreamTypeId==8 || ws.WorkstreamTypeId==9),'ss-ws-am':ws.WorkstreamTypeId==2,'workstream-readonly' : IsWorkStreamSelectable}">
                                                        {{ ws.Workstream }}</span></span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li> -->
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <!-- End of scope selector code-->

          <!-- <div>
            <span class="ss-client" style="margin: 2%;"><img src="../assets/images/ss_client.png" alt="Client"
                style="height: 20px;" />Client</span>
            <span class="ss-folder" style="margin: 2%;"><img src="../assets/images/ss_folder.png" alt="Group"
                style="height: 20px;" />Group</span>
            <span class="ss-opportunity" style="margin: 2%;"><img src="../assets/images/ss_opportunity.png"
                alt="Opportunity" style="height: 20px;;margin:1%;" />Opportunity</span>
          </div>
          <div style="margin-top: 3%">
            <span class="ss-contract" style="margin: 2%;"><img src="../assets/images/ss_contract.png" alt="Contract"
                style="height: 20px;;" />Contract</span>
            <span class="ss-initiative" style="margin: 2%;"><img src="../assets/images/ss_initiative.png"
                alt="Initiative" style="height: 20px;" />Initiative</span>
          </div> -->
          <div style="float:right;">
            <!--<button class="btn btn-primary">Set as Default</button>-->
            <button type="button" class="btn btn-primary" style="height:35px;margin:6px;position:inherit;"
              (click)="closeScopeSelectorApply(true);Apply();">Apply</button>
          </div>




        </form>
      </div>
    </div>
    <!-- /scope selector -->
    <!-- <a href="javascript:;" class="myw-header-item myw-help" title="Help">
      <img src="../assets/images/help.svg" alt="Help" />
      <span class="sr-only">Help</span>
    </a>
    <a href="javascript:;" class="myw-header-item myw-notifications" title="Notifications">
      <img src="../assets/images/uva.svg" alt="Notifications" />
      <span class="sr-only">Notifications</span>
    </a>
    <a href="javascript:;" class="myw-header-item myw-settings" title="Settings">
      <img src="../assets/images/settings.svg" alt="Settings" />
      <span class="sr-only">Settings</span>
    </a> -->
    <!-- <div class="dropdown dropright d-flex"> -->
      <!-- <p style="margin:15px -8px;">{{ajWebService.enterpriseId}}</p> -->
      <div class="dropdown dropright dropflat d-flex" style="margin-left: 17px;margin-top: 14px;">
        <a href="javascript:;" style="padding-left: 10px; padding-right: 10px;" class="myw-navbar-action" title="Link" role="button" data-toggle="dropdown"
            data-display="static" aria-haspopup="true" aria-expanded="true" data-trigger="hover"
            id="Linkview"><img src="../../assets/images/help.svg" alt="Filter" /></a>
        <div class="dropdown-menu" style="height: 30px;"aria-labelledby="Linkview">
            <form class="dropdown-body">
              <a href="../assets/AJ User Manual_v1.0.pptx" class="dropdown-item">AJ User Manual</a>
            </form>
        </div>
    </div>
      <div class="dropdown dropright dropflat d-flex" style="margin-left: 17px;margin-top: 14px;">
        <a href="javascript:;" class="myw-navbar-action" title="" role="button" data-toggle="dropdown"
            data-display="static" aria-haspopup="true" aria-expanded="true" data-trigger="hover"
            id="Linkview"><img src="assets/images/user.svg" alt="Filter" />
            <img src="assets/images/caret-down.svg" alt="Filter" style="width: 0.6rem;margin-left: 7px;"/></a>

            
        <div class="dropdown-menu dropdown-small" style="height: 120px;width:16rem" aria-labelledby="Linkview">
            <form class="dropdown-body">           
                <p style="margin:15px 8px;">Welcome, <b>{{ajWebService.enterpriseId}}</b></p>  
                        
                <button _ngcontent-tdi-c93="" type="button" class="btn btn-primary" (click)="logout()" style="margin-left: 12px;">Logout</button>   
            </form>
        </div>
    </div>    
      <!-- <a href="javascript:;" class="myw-login myw-header-item dropdown-toggle" role="button" data-display="static"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="loginDetails" title="demo.user">
        <img src="../assets/images/user.svg" alt="demo.user" />
        <span class="sr-only">demo.user</span>
      </a> -->
      <!-- <div class="dropdown-menu dropdown-md" aria-labelledby="loginDetails">
        <div class="dropdown-body">
          <div class="p-3">
            <p>Welcome, <strong>demo.user</strong></p>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</header>
<!-- /header -->

<!-- topbar -->
<div class="myw-topbar">
  <div class="myw-container d-flex flex-wrap">
    <span style="font-size: 1.125rem;
    font-weight: 600;
    padding: .5rem 0;
    margin-right: 1rem;
    color: #fff;">Automation Journey</span>
  </div>
</div>
<!-- /topbar -->

<router-outlet></router-outlet>