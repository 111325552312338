<!-- navbar -->
<div class="myw-navbar {{displayNavbar == true ? '' : 'collapsed'}}" id="nav-pin">
    <div class="myw-inner myw-container d-flex flex-wrap">
        <div class="d-flex flex-nowrap justify-content-end myw-navbar-actions ml-auto {{displayNavbarIconsWithText == true ? 'myw-navbar-active' : ''}}" id="nav-toggle">
            <div class="myw-navbar-action" title="Search">
                <input #searchText (keyup)="searchOpportunityLists(searchText.value)" type="search"
                    class="form-control form-control-sm" (search)="clearSearch()" id="searchbox"
                    (keydown)="$event.keyCode != 13 ? $event:$event.preventDefault()" placeholder="Search...">
                <!-- <div (click)="clearSearch()"><img src="../../assets/images/close.svg" alt="Close" /></div> -->
            </div>
            <a (click)="showSummary = !showSummary" [ngClass]="{'collapsed' : showSummary}"
                class="myw-navbar-action myw-navbar-action-toggle" role="button" data-toggle="collapse"
                aria-expanded="false" data-trigger="hover" data-placement="bottom"
                title="{{!showSummary ? 'Show Graphs' : 'Hide Graphs'}}"><img src="../../assets/images/view.svg"
                    alt="Summary"><span>{{!showSummary ? 'Show Graphs' : 'Hide Graphs'}}</span></a>
            <a (click)="grouping = !grouping;changeClass();" href="javascript:;"
                class="myw-navbar-action myw-navbar-group-toggle" role="button" aria-expanded="false"
                data-trigger="hover" data-placement="bottom"
                title="{{grouping ? 'Ungroup by Queues' : 'Group by Queues'}}">
                <img src="../../assets/images/queue.svg" class="group">
                <img src="../../assets/images/ungroup.svg" class="ungroup d-none">
                <span>{{grouping ? 'Ungroup by Queues' : 'Group by Queues'}}</span>
            </a>
            <div class="dropdown dropright dropflat d-flex">
                <a href="javascript:;" class="myw-navbar-action" data-toggle-second="tooltip" data-placement="bottom"
                    title="Filter" role="button" data-toggle="dropdown" data-display="static" aria-haspopup="true"
                    aria-expanded="true" id="filterview" ><img src="../../assets/images/filter.svg"
                        alt="Filter"/><span>Filter</span></a> 
                <div class="dropdown-menu dropdown-xl" aria-labelledby="filterview" style="height: 320px; overflow-y: scroll;" >
                    <div class="d-flex align-items-center justify-content-between p-3">
                        <h6 class="dropdown-title">Filter</h6>
                        <a href="javascript:;" class="myw-dropdown-close" title="Close"><span
                                class="sr-only">Close</span></a>
                    </div>
                    <form class="dropdown-body1 px-3 pb-3">
                        <div class='row'>
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-label">CMS</label>
                                    <select class="form-control" (change)='getSetcmsFilter()'  [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedcmsFilter">
                                        <option selected value="undefined">All</option>
                                        <option *ngFor="let summary of ajWebService.cmsData" [ngValue]="summary.CMSUId">
                                            {{summary.Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-label">Work Stream</label>
                                    <select class="form-control"  [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedcmsFlt">
                                        <option *ngFor="let summary of wsFilter"  [ngValue]="summary.WorkstreamUId">
                                            {{summary.Workstream}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-label">Opportunity Source</label>
                                    <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedSource">
                                        <option selected *ngFor="let summary of source"  [value]="summary.value">
                                            {{summary.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="myw-aj-adv-fitler d-none">
                            <h6 class="dropdown-title">Advanced Filters</h6>
                            <hr>
                            <div class="row mt-3">
                                <div class="col-4">
                                    <div class="form-group">                               
                                        <label class="form-label">By Date</label>
                                        <div class="input-group">
                                            <ejs-datepicker class="form-label"                                          
                                                 format='MM/dd/yyyy' [allowEdit]=false
                                              placeholder='From' (change)="ValueChangeFrom($event)"  [value]=dateValueFrom >                                              
                                            </ejs-datepicker>
                                            </div>                                        
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label class="form-label">&nbsp;</label>                                   
                                        <ejs-datepicker class="form-label"
                                        format='MM/dd/yyyy' [allowEdit]=false
                                     placeholder='To' (change)="ValueChangeTo($event)" [value]=dateValueTo  [min]=dateValueFrom >
                                   </ejs-datepicker>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label class="form-label" style="font-weight:400;display:inline-block;overflow:hidden;white-space:nowrap;font-size:12px;">By Opportunity Status</label>
                                        <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedStatus" style="margin-top: -0.75em;">
                                            <option selected *ngFor="let summary of status"  [value]="summary.value">
                                                {{summary.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div> 
                                <div class="col-5">
                                    <label class="form-label">By Actual cost Savings</label>
                                    <input type="range" class="myw-range" [min]="0" [max]=maxActualSaving [value]=getActualSaving (change)="valueChanged($event.target.value)">        
                                </div>
                                <div class="col-5"><output style="margin-top: 25px;margin-left: -17px;">${{changeValue}}</output></div>    
                             </div>
                        </div>
                        <div class="myw-actions d-flex justify-content-end align-items-center mt-3">
                            <button type="button" class="btn btn-secondary adv-btn" (click)="onAdvClick()">Advanced Filter</button>
                            <button type="button" class="btn btn-secondary clear-btn d-none" (click)="onCancleClick()">Cancel</button>
                            <button type="button" class="btn btn-secondary ml-auto clear-btn" (click)="onClearClick()">Clear All</button>
                            <button type="button" class="btn btn-primary" (click)="filterApply=true; populateData()">Apply</button>
                        </div>
                    </form>
                </div>
            </div>

            <!-- <div [class]="showdata == true ? 'dropdown dropright dropflat d-flex show' : 'dropdown dropright dropflat d-flex'"  id="bar">
                <a href="javascript:;" (click)="clickhold()"  class="myw-navbar-action" role="button" title="Filter" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false" id="filterPopup" data-toggle-tooltip="tooltip" data-placement="bottom" data-trigger="hover"><img src="assets/images/filter.svg" alt="Filter" /><span>Filter</span></a>
                <div [class]="showdata == true ? 'dropdown-menu dropdown-xl show' : 'dropdown-menu dropdown-xl'"  aria-labelledby="filterPopup" id="popUp">
                    <div class="d-flex align-items-center justify-content-between p-3">
                        <h6 class="dropdown-title">Filter</h6>
                        <a href="javascript:;" class="myw-dropdown-close" title="Close" aria-expanded="true"><span
                                class="sr-only">Close</span></a>
                    </div>
                    <form class="dropdown-body px-3 pb-3">
                        <div class="row">
                           
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-label">Queue</label>
                                    <ejs-datepicker class="form-label"
                                           
                                    format='MM/dd/yyyy' [allowEdit]=true
                                 placeholder='From'
                                 [(ngModel)]="ByDatefilter"
                                 (change)="ByDatefilterchange($event)">
                               </ejs-datepicker>
                                    <select class="form-control" [(ngModel)]="selectedDataSourceNameFilter" [ngModelOptions]="{standalone: true}" (change)='SetDataSourceNameFilter(selectedDataSourceNameFilter)' >
                                    <option *ngFor="let summary of BluePrintFilterQue" [value]="summary">
                                        {{summary}}
                                    </option>
                                </select>
                                </div>
                            </div>
                        </div>
                        <div class="myw-actions d-flex justify-content-end align-items-center mt-3">
                            <button type="button" class="btn btn-secondary mr-auto" >Clear
                                All</button>
                            <button type="button" class="btn btn-primary" >Apply</button>
                        </div>
                    </form>
                </div>
            </div> -->




            <a (click)="ExcelReport();" href="javascript:;" class="myw-navbar-action" data-toggle="tooltip" data-placement="bottom"
                data-trigger="hover" title="Download Report"><img src="../../assets/images/download.svg"
                    alt="Download" /><span>Download Report</span></a>
            <div class="dropdown dropright dropflat d-flex">
                <a href="javascript:;" class="myw-navbar-action" title="Link" role="button" data-toggle="dropdown"
                    data-display="static" aria-haspopup="true" aria-expanded="true" data-trigger="hover"
                    id="Linkview"><img src="../../assets/images/link.svg" alt="Filter" /><span>Link</span></a>
                <div class="dropdown-menu dropdown-small" aria-labelledby="Linkview">
                    <form class="dropdown-body">
                        <a (click)="IOMRedirect();" target="blank" rel="noopener noreferrer" class="dropdown-item">IOM</a>
                    </form>
                </div>
            </div>
            <a (click)="displayNavbarIconsWithText = !displayNavbarIconsWithText" class="myw-navbar-toggle" data-trigger="hover" title="Toggle Labels"><img
                    src="../../assets/images/caret-down.svg" alt="" /><span class="sr-only">Toggle Labels</span></a>
        </div>
    </div>
    <a (click)="displayNavbar = !displayNavbar" class="myw-navbar-pin" data-trigger="hover" title="Toggle Navbar"><img
            src="../../assets/images/pin.svg" alt="" /><span class="sr-only">Toggle
            Navbar</span></a>
</div>
<!-- /navbar -->

<!-- content -->
<div id="main" class="myw-content d-flex flex-column flex-fill">
    <div class="myw-container py-3" style="position: relative; background-color: white;">
        <!-- Carousel -->
        <div class="myw-carousel myw-chart-carousel collapse show" [hidden]="!showSummary">
            <div id="summaryCarousel" class="carousel slide" data-ride="carousel" data-interval="false">
                <div class="carousel-inner">
                    <div *ngIf="toggleChart==false; else showAssetGraph" class="carousel-item active">
                        <div class="form-row" style="margin-left: -15px;">
                            <div class="col-6 col-xl-2">
                                <div class="card active">
                                    <div class="card-header">
                                        <h6 class="card-title">Automation Opportunities</h6>
                                    </div>
                                    <div class="card-body" id="APDonutChart" style="width:100%">
                                        <app-donut-chart></app-donut-chart>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-xl-4">
                                <div class="card">
                                    <div class="card-header">
                                        <h6 class="card-title">Ticket Distribution</h6>
                                        <a href="javascript:;" title="Expand" data-toggle="modal"
                                            (click)="openTicketDistributionChart()" data-target="#ticketdismodal"><img
                                                src="../../assets/images/expand.svg" alt="Expand" /></a>
                                    </div>
                                    <!-- <div class="card-body" id="ticketDistHeatMap" #ticketDistHeatMap>
                                    </div> -->
                                    <div class="card-body" id="ticketDistHeatMap">
                                        <app-heat-map-chart></app-heat-map-chart>
                                    </div>
                                    <!-- <div>
                                        <svg id="ticketDistHeatMapLegend"></svg>
                                    </div>                                       -->

                                </div>
                            </div>
                            <div class="col-6 col-xl-3">
                                <div class="card">
                                    <div class="card-header">
                                        <h6 class="card-title">Use Cases Implemented</h6>
                                        <a href="javascript:;" title="Expand" data-toggle="modal"
                                            (click)="openUseCasesImplementedChart()" data-target="#usecasemodal"><img
                                                src="../../assets/images/expand.svg" alt="Expand" /></a>
                                    </div>
                                    <div class="card-body" style="width: 300px;height:500px;">
                                        <mri-use-cases-implementation></mri-use-cases-implementation>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-xl-3">
                                <div class="card">
                                    <div class="card-header">
                                        <h6 class="card-title">Outcomes</h6>
                                    </div>
                                    <div class="card-body" style="width:100%,height=500px">
                                        <mri-bullet-chart [savedCostTotal]="savedCostTotal"
                                            [savedEffortTotal]="savedEffortTotal"></mri-bullet-chart>
                                        <effort-bullet-chart></effort-bullet-chart>
                                        <!-- <div id="mri-bullet-chart" style="height:150px">
                                            
                                        </div>
                                        <div id="effort-bullet-chart" style="height:100px">
                                            
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #showAssetGraph>
                        <div class="carousel-item active">
                            <div class="form-row">
                                <div class="col-6 col-xl-4">
                                    <div class="card">
                                        <div class="card-header">
                                            <h6 class="card-title">Ticket Distribution</h6>
                                            <a href="javascript:;" title="Expand" data-toggle="modal"
                                                (click)="openTicketDistributionChart()" data-target="#ticketdismodal"><img
                                                    src="../../assets/images/expand.svg" alt="Expand" /></a>
                                        </div>
                                        <!-- <div class="card-body" id="ticketDistHeatMap" #ticketDistHeatMap>
                                        </div> -->
                                        <div class="card-body" id="ticketDistHeatMap">
                                            <app-heat-map-chart></app-heat-map-chart>
                                        </div>
                                        <!-- <div>
                                            <svg id="ticketDistHeatMapLegend"></svg>
                                        </div>                                       -->
    
                                    </div>
                                </div>
                                <div class="col-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-header">
                                            <h6 class="card-title">Use Cases Implemented</h6>
                                            <a href="javascript:;" title="Expand" data-toggle="modal"
                                                (click)="openUseCasesImplementedChart()" data-target="#usecasemodal"><img
                                                    src="../../assets/images/expand.svg" alt="Expand" /></a>
                                        </div>
                                        <div class="card-body" style="width: 300px;height:500px;">
                                            <mri-use-cases-implementation></mri-use-cases-implementation>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-header">
                                            <h6 class="card-title">Outcomes</h6>
                                        </div>
                                        <div class="card-body" style="width:100%,height=500px">
                                            <mri-bullet-chart [savedCostTotal]="savedCostTotal"
                                                [savedEffortTotal]="savedEffortTotal"></mri-bullet-chart>
                                            <effort-bullet-chart></effort-bullet-chart>
                                            <!-- <div id="mri-bullet-chart" style="height:150px">
                                                
                                            </div>
                                            <div id="effort-bullet-chart" style="height:100px">
                                                
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-xl-2">
                                        <div class="card">
                                            <div class="card-header">
                                                <h6 class="card-title">Asset Provisioning Status</h6>
                                            </div>
                                            <div class="card-body" style="width:100%">
                                                <app-asset-provisioning-status></app-asset-provisioning-status>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <!-- <div class="carousel-item">
                        <div class="form-row">
                            <div class="col-6 col-xl-2">
                                <div class="card">
                                    <div class="card-header">
                                        <h6 class="card-title">Asset Provisioning Status</h6>
                                    </div>
                                    <div class="card-body" style="width:100%">
                                        <app-asset-provisioning-status></app-asset-provisioning-status>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div> -->
                </div>
                <ol class="carousel-indicators">
                    <li data-target="#summaryCarousel" data-slide-to="0" [ngClass]="{'active': !toggleChart, 'na': toggleChart}"></li>
                    <li data-target="#summaryCarousel" data-slide-to="1" [ngClass]="{'active': toggleChart, 'na': !toggleChart}"></li>
                </ol>
                <a *ngIf="toggleChart" href="#summaryCarousel" class="carousel-control-prev" role="button" data-slide="prev" (click)=" toggleChart=!toggleChart; populateData()">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a *ngIf="!toggleChart" href="#summaryCarousel" class="carousel-control-next" role="button" data-slide="next" (click)=" toggleChart=!toggleChart; populateData()">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
        <!-- Carousel -->

        <ngx-spinner fullScreen="false" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff"
            type="ball-scale-multiple">
            <p style="font-size: 20px; color: white">Loading data...</p>
        </ngx-spinner>

        <!-- Accordion (o queues) -->
        <div *ngIf="grouping" class="myw-accordion-wrap">
            <div class="myw-accordion" *ngFor="let oq of opportunityQueues">
                <div class="myw-accordion-header d-flex">
                    <div class="row w-100">
                        <div class="col-2 myw-accordion-title">
                            <h6>{{oq.queueName}}</h6>
                        </div>
                        <div class="col-2 myw-500"><span class="myw-chips">{{oq.Backlog.length + oq.InProgress.length +
                                oq.Complete.length + oq.Cancelled.length}}</span>
                        </div>
                        <div class="col-3 myw-500">Planned Savings($) : <span>{{oq.plannedSavingsOfQueue}}</span> </div>
                        <div class="col-3 myw-500">Actual Savings($) : <span>{{oq.actualSavingsOfQueue}}</span></div>
                    </div>
                    <a href="#{{'expand' + oq.queueId}}" id="{{'expandsearch' + oq.queueId}}"
                        class="myw-accordion-toggle ml-auto {{oq.queueId == queueId ? '':'collapsed'}}" role="button"
                        data-toggle="collapse" aria-expanded="false" (click)="openOpportunityQueue(oq.queueId)"></a>
                </div>
                <div class="myw-accordion-content collapse p-3" id="{{'expand' + oq.queueId}}"
                    *ngIf="queueId == oq.queueId">
                    <div class="myw-aj-bdy-container myw-scrollbar">
                        <div class="row" cdkDropListGroup>
                            <div class="col-3 statusListPadding">
                                <div class="myw-aj-col-head d-flex align-items-center">
                                    <div>Backlog <span>{{oq.Backlog.length}}</span></div>
                                    <a href="javascript:;" class="ml-auto mr-2"><img src="../../assets/images/sort.svg"
                                            alt="Sort" (click)="sortGroupedOpportunities(oq.queueId, 'Backlog')" /></a>
                                    <a (click)="APHome()" target="blank" rel="noopener noreferrer"><img src="../../assets/images/plus.svg"
                                            alt="" /></a>
                                </div>
                                <div class="myw-aj-bdy-content" cdkDropList
                                    id="groupedBacklogListContainer-{{oq.queueId}}" [cdkDropListData]="oq.Backlog"
                                    (cdkDropListDropped)="dragAndDropGroupedOpportunity($event)">
                                    <div *ngFor="let ol of oq.Backlog" id="{{ol.OpportunityId}}" cdkDrag>
                                        <div class="card" style="margin-bottom: 7px;max-height: 100px;height: 100%;">
                                            <div class="card-inner">
                                                <div class="card-header">
                                                    <div *ngIf="ol.Towername!=null; else towernull" tooltip="{{ol.OpportunityName}}-{{ol.Towername}}">
                                                        {{ (ol.OpportunityName.length > 45 ) ? (ol.OpportunityName |
                                                        slice:0:45) + '...' : ol.OpportunityName}}
                                                    </div>
                                                    <ng-template #towernull>
                                                    <div tooltip="{{ol.OpportunityName}}">
                                                        {{ (ol.OpportunityName.length > 45 ) ? (ol.OpportunityName |
                                                        slice:0:45) + '...' : ol.OpportunityName}}
                                                    </div>
                                                    </ng-template>
                                                </div>
                                                <div class="card-body">
                                                    <div class="myw-aj-pas d-flex">
                                                        <div class="myw-aj-pas-item">
                                                            <div>Planned Savings ($) :
                                                                <span style="margin-left: 48px ;">{{ol.SavedCost != 0 ? ((ol.SavedCost >= 1000 ? ol.SavedCost/1000 : ol.SavedCost) | number : '1.2-2') : '0.00'}} {{ol.SavedCost >= 1000 ? 'k' : ''}}</span>
                                                            </div>
                                                            <!-- <div *ngIf="ol.PlannedStartDate == null">Planned Savings ($) : 0.00 </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 statusListPadding">
                                <div class="myw-aj-col-head d-flex align-items-center">
                                    <div>In progress <span>{{oq.InProgress.length}}</span></div>
                                    <a href="javascript:;" class="ml-auto"><img src="../../assets/images/sort.svg"
                                            alt="Sort"
                                            (click)="sortGroupedOpportunities(oq.queueId,'InProgress')" /></a>
                                </div>
                                <div class="myw-aj-bdy-content" cdkDropList
                                    id="groupedInProgressListContainer-{{oq.queueId}}" [cdkDropListData]="oq.InProgress"
                                    (cdkDropListDropped)="dragAndDropGroupedOpportunity($event)">
                                    <div *ngFor="let ol of oq.InProgress" id="{{ol.OpportunityId}}" cdkDrag>
                                        <div class="card" style="margin-bottom: 7px;">
                                            <div class="card-inner">
                                                <div class="card-header d-flex">
                                                    <div *ngIf="ol.Towername!=null; else towernull" tooltip="{{ol.OpportunityName}}-{{ol.Towername}}">
                                                        {{ (ol.OpportunityName.length > 45 ) ? (ol.OpportunityName |
                                                        slice:0:45) + '...' : ol.OpportunityName}}
                                                    </div>
                                                    <ng-template #towernull>
                                                    <div tooltip="{{ol.OpportunityName}}">
                                                        {{ (ol.OpportunityName.length > 45 ) ? (ol.OpportunityName |
                                                        slice:0:45) + '...' : ol.OpportunityName}}
                                                    </div>
                                                    </ng-template>
                                                    <a target="blank" rel="noopener noreferrer" class="ml-auto" style="cursor: pointer;"
                                                        (click)="ApRedirect(ol.BluePrintId,ol.AppName,ol.Type,ol.DeepDiveId,ol.CategoryId,ol.SubCategoryId,ol.cmsUID,ol.wsUId)">
                                                        <img src="../../assets/images/edit.svg" alt="" />
                                                    </a>
                                                </div>
                                                <div class="card-body">
                                                    <div class="myw-aj-pas d-flex">
                                                        <div class="myw-aj-pas-item-container">
                                                            <div class="myw-aj-pas-item">
                                                                <div *ngIf="ol.PlannedStartDate != null">Planned Savings
                                                                    ($) : <span style="margin-left: 48px;">
                                                                        {{ol.currentPlan != 0 ? ((ol.currentPlan >= 1000 ? ol.currentPlan/1000 : ol.currentPlan) | number : '1.2-2') : '0.00'}} {{ol.currentPlan >= 1000 ? 'k' : ''}}</span>
                                                                </div>
                                                                <div *ngIf="ol.PlannedStartDate == null">Planned Savings
                                                                    ($) : <span style="margin-left: 48px ;">0.00</span> </div>
                                                            </div>
                                                            <div class="myw-aj-pas-item">
                                                                <div>Benefit Realization Date : <div
                                                                        style="width: 39%;float:right;margin: -8% 2%; font-family: sans-serif">
                                                                        <ejs-datepicker class="datepicker"
                                                                            format='MM/dd/yyyy' [allowEdit]=false
                                                                            placeholder='Select a date'
                                                                            [(ngModel)]="ol.PlannedStartDate"
                                                                            (change)="UpdatePlannedDate(ol.OpportunityId,$event)">
                                                                        </ejs-datepicker>
                                                                    </div>
                                                                </div>
                                                                <div style="color: red;" *ngIf="ol.PlannedStartDate == undefined">
                                                                    Please select a benefit realization date.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="myw-aj-cmore">
                                                            <a class="collapsed" data-toggle="collapse"
                                                                href="#{{'inProgressCollapse' + ol.OpportunityId}}"
                                                                role="button" aria-expanded="false"><img
                                                                    src="../../assets/images/arrow-down.svg"
                                                                    alt="" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="collapse" id="{{'inProgressCollapse' + ol.OpportunityId}}">
                                                <div class="myw-aj-asset-container">
                                                    <div class="myw-aj-asset-list d-flex"
                                                        *ngFor="let asset of ol.Assets">
                                                        <div class="dropdown dropcenter"
                                                            *ngIf="asset.OrderStatus == 'Backlog'">
                                                            <a class="dropdown-toggle critical" type="button"
                                                                href="{{'Backlog' + asset.AssetId}}"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">&nbsp;</a>
                                                            <div class="dropdown-menu mt-2"
                                                                id="{{'Backlog' + asset.AssetId}}">
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'InProgress', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'InProgress', ol.PlannedStartDate)"><span
                                                                        class="medium">&nbsp;</span></a>
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'InProgress', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete', ol.PlannedStartDate)"><span
                                                                        class="low">&nbsp;</span></a>
                                                            </div>
                                                        </div>
                                                        <div class="myw-aj-asset"
                                                            *ngIf="asset.OrderStatus == 'Backlog'">
                                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                                            <span class="ml-1">{{asset.AssetName}}</span>
                                                        </div>

                                                        <div class="dropdown dropcenter"
                                                            *ngIf="asset.OrderStatus == 'InProgress'">
                                                            <a class="dropdown-toggle medium" type="button"
                                                                href="{{'InProgress' + asset.AssetId}}"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">&nbsp;</a>
                                                            <div class="dropdown-menu mt-2"
                                                                id="{{'InProgress' + asset.AssetId}}">
                                                                <!-- <a class="dropdown-item" (click)="changeAssetStatus(oq.queueId,'InProgress', ol.OpportunityId, asset.AssetId, asset.AssetName, 'Backlog')"><span
                                                                        class="critical">&nbsp;</span></a> -->
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'InProgress', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete', ol.PlannedStartDate)"><span
                                                                        class="low">&nbsp;</span></a>
                                                            </div>
                                                        </div>
                                                        <div class="myw-aj-asset"
                                                            *ngIf="asset.OrderStatus == 'InProgress'">
                                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                                            <span class="ml-1">{{asset.AssetName}}</span>
                                                        </div>

                                                        <div class="dropdown dropcenter"
                                                            *ngIf="asset.OrderStatus == 'Complete'">
                                                            <a class="dropdown-toggle low" type="button"
                                                                href="{{'Complete' + asset.AssetId}}"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">&nbsp;</a>
                                                            <!-- <div class="dropdown-menu mt-2" id="{{'Complete' + asset.AssetId}}">
                                                                <a class="dropdown-item" (click)="changeAssetStatus(oq.queueId,'InProgress', ol.OpportunityId, asset.AssetId, asset.AssetName, 'Backlog')"><span
                                                                        class="critical">&nbsp;</span></a>
                                                                <a class="dropdown-item" (click)="changeAssetStatus(oq.queueId,'InProgress', ol.OpportunityId, asset.AssetId, asset.AssetName, 'InProgress')"><span
                                                                        class="medium">&nbsp;</span></a>
                                                            </div> -->
                                                        </div>
                                                        <div class="myw-aj-asset"
                                                            *ngIf="asset.OrderStatus == 'Complete'">
                                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                                            <span class="ml-1">{{asset.AssetName}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 statusListPadding">
                                <div class="myw-aj-col-head d-flex align-items-center">
                                    <div>Completed <span>{{oq.Complete.length}}</span></div>
                                    <a href="javascript:;" class="ml-auto"><img src="../../assets/images/sort.svg"
                                            alt="Sort" (click)="sortGroupedOpportunities(oq.queueId,'Complete')" /></a>
                                </div>
                                <div class="myw-aj-bdy-content" cdkDropList
                                    id="groupedCompleteListContainer-{{oq.queueId}}" [cdkDropListData]="oq.Complete"
                                    (cdkDropListDropped)="dragAndDropGroupedOpportunity($event)">
                                    <div *ngFor="let ol of oq.Complete" id="{{ol.OpportunityId}}" cdkDrag>
                                        <div class="card" style="margin-bottom: 7px;">
                                            <div class="card-inner">
                                                <div class="card-header d-flex">
                                                    <div *ngIf="ol.Towername!=null; else towernull" tooltip="{{ol.OpportunityName}}-{{ol.Towername}}">
                                                        {{ (ol.OpportunityName.length > 45 ) ? (ol.OpportunityName |
                                                        slice:0:45) + '...' : ol.OpportunityName}}
                                                    </div>
                                                    <ng-template #towernull>
                                                    <div tooltip="{{ol.OpportunityName}}">
                                                        {{ (ol.OpportunityName.length > 45 ) ? (ol.OpportunityName |
                                                        slice:0:45) + '...' : ol.OpportunityName }}
                                                    </div>
                                                    </ng-template>
                                                    <a href="javascript:;" class="ml-auto" data-toggle="modal"
                                                        data-target="#roiGraphmodal"
                                                        (click)="viewROI(ol.OpportunityId)"><img tooltip="ROI Graph"
                                                            src="../../assets/images/graph.svg" alt="" /></a>
                                                </div>
                                                <div class="card-body">
                                                    <div class="myw-aj-pas d-flex">
                                                        <div class="myw-aj-pas-item-container">
                                                            <div class="myw-aj-pas-item">
                                                                <div *ngIf="ol.PlannedStartDate != null">Planned Savings ($) : <span style="margin-left: 48px ;">{{ol.currentPlan != 0 ? ((ol.currentPlan >= 1000 ? ol.currentPlan/1000 : ol.currentPlan) | number : '1.2-2') : '0.00'}} {{ol.currentPlan >= 1000 ? 'k' : ''}}
                                                                    </span>
                                                                </div>
                                                                <div *ngIf="ol.PlannedStartDate == null">Planned Savings ($) : <span style="margin-left: 48px ;">0.00</span> </div>
                                                                <div style="margin-top: 5px;">Actual Savings ($) : <span style="margin-left: 56px ;">{{ol.actualSavings != 0 ? ((ol.actualSavings >= 1000 ? ol.actualSavings/1000 : ol.actualSavings) | number : '1.2-2') : '0.00'}} {{ol.actualSavings >= 1000 ? 'k' : ''}}</span></div>
                                                            </div>
                                                            <div class="myw-aj-pas-item">
                                                                <div>Benefit Realization Date : <div
                                                                        style="width: 39%;float:right;margin: -8% 2%; font-family: sans-serif">
                                                                        <ejs-datepicker class="datepicker"
                                                                            format='MM/dd/yyyy' [allowEdit]=false
                                                                            placeholder='Select a date'
                                                                            [(ngModel)]="ol.PlannedStartDate"
                                                                            (change)="UpdatePlannedDate(ol.OpportunityId,$event)">
                                                                        </ejs-datepicker>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="myw-aj-cmore">
                                                            <a class="collapsed" data-toggle="collapse"
                                                                id="{{'toggleCompletedCollapse' + ol.OpportunityId}}"
                                                                href="#{{'completedCollapse' + ol.OpportunityId}}"
                                                                (click)="openAssetDataAndCharts(ol.OpportunityId, ol.PlannedStartDate)"
                                                                role="button" aria-expanded="false"><img
                                                                    src="../../assets/images/arrow-down.svg"
                                                                    alt="" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="collapse" id="{{'completedCollapse' + ol.OpportunityId}}"
                                                *ngIf="ol.OpportunityId == opportunityId">
                                                <div class="myw-aj-asset-container">
                                                    <div class="myw-aj-asset-list d-flex"
                                                        *ngFor="let asset of ol.Assets">
                                                        <div class="dropdown dropcenter"
                                                            *ngIf="asset.OrderStatus == 'Backlog'">
                                                            <a class="dropdown-toggle critical" type="button"
                                                                href="{{'Backlog' + asset.AssetId}}"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">&nbsp;</a>
                                                            <div class="dropdown-menu mt-2"
                                                                id="{{'Backlog' + asset.AssetId}}">
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'Complete', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'InProgress',ol.PlannedStartDate)"><span
                                                                        class="medium">&nbsp;</span></a>
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'Complete', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete',ol.PlannedStartDate)"><span
                                                                        class="low">&nbsp;</span></a>
                                                            </div>
                                                        </div>
                                                        <div class="myw-aj-asset"
                                                            *ngIf="asset.OrderStatus == 'Backlog'">
                                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                                            <span class="ml-1">{{asset.AssetName}}</span>
                                                        </div>

                                                        <div class="dropdown dropcenter"
                                                            *ngIf="asset.OrderStatus == 'InProgress'">
                                                            <a class="dropdown-toggle medium" type="button"
                                                                href="{{'InProgress' + asset.AssetId}}"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">&nbsp;</a>
                                                            <div class="dropdown-menu mt-2"
                                                                id="{{'InProgress' + asset.AssetId}}">
                                                                <!-- <a class="dropdown-item" (click)="changeAssetStatus(oq.queueId,'Complete', ol.OpportunityId, asset.AssetId, asset.AssetName, 'Backlog')"><span
                                                                        class="critical">&nbsp;</span></a> -->
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'Complete', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete',ol.PlannedStartDate)"><span
                                                                        class="low">&nbsp;</span></a>
                                                            </div>
                                                        </div>
                                                        <div class="myw-aj-asset"
                                                            *ngIf="asset.OrderStatus == 'InProgress'">
                                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                                            <span class="ml-1">{{asset.AssetName}}</span>
                                                        </div>

                                                        <div class="dropdown dropcenter"
                                                            *ngIf="asset.OrderStatus == 'Complete'">
                                                            <a class="dropdown-toggle low" type="button"
                                                                href="{{'Complete' + asset.AssetId}}"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">&nbsp;</a>
                                                            <!-- <div class="dropdown-menu mt-2" id="{{'Complete' + asset.AssetId}}">
                                                                <a class="dropdown-item" (click)="changeAssetStatus(oq.queueId,'Complete', ol.OpportunityId, asset.AssetId, asset.AssetName, 'Backlog')"><span
                                                                        class="critical">&nbsp;</span></a>
                                                                <a class="dropdown-item" (click)="changeAssetStatus(oq.queueId,'Complete', ol.OpportunityId, asset.AssetId, asset.AssetName, 'InProgress')"><span
                                                                        class="medium">&nbsp;</span></a>
                                                            </div> -->
                                                        </div>
                                                        <div class="myw-aj-asset"
                                                            *ngIf="asset.OrderStatus == 'Complete'">
                                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                                            <span class="ml-1">{{asset.AssetName}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-inner">
                                                    <div class="myw-aj-tile my-2">
                                                        <div class="myw-aj-thead d-flex">
                                                            <div>Ticket Volume</div>
                                                            <a href="javascript:;" data-toggle="modal"
                                                                data-target="#ticketmodal"
                                                                (click)="editTicketVolume(oq.queueId, ol.OpportunityId, ol.OpportunityName, ol.PlannedStartDate)"
                                                                class="ml-auto"><img src="../../assets/images/edit.svg"
                                                                    alt="Edit" /></a>
                                                            <a href="javascript:;" data-toggle="modal"
                                                                (click)="viewTicketVolume(ol.OpportunityId)"
                                                                data-target="#ticketVolmodal"><img
                                                                    tooltip="Ticket Volume Graph"
                                                                    src="../../assets/images/graph.svg"
                                                                    alt="Graph" /></a>
                                                        </div>
                                                        <div class="myw-aj-tv-chart my-2"
                                                            id="{{'TicketVolDonutChart-' + oq.queueId + '-' + ol.OpportunityId}}"
                                                            style="width: 100%;">
                                                            <app-donut-chart-ticket
                                                                [chartIdSelector]="'TicketVolDonutChart-' + oq.queueId + '-' + ol.OpportunityId">
                                                            </app-donut-chart-ticket>
                                                        </div>
                                                    </div>
                                                    <div class="myw-aj-tile my-2">
                                                        <div class="myw-aj-thead d-flex" style="margin-top: 20px;">
                                                            <div>Savings ($)</div>
                                                            <a href="javascript:;" data-toggle="modal"
                                                                data-target="#savingmodal"
                                                                (click)="editSavings(oq.queueId, ol.OpportunityId, ol.OpportunityName, ol.PlannedStartDate)"
                                                                class="ml-auto mr-2"><img
                                                                    src="../../assets/images/edit.svg" alt="Edit" /></a>
                                                            <a href="javascript:;" data-toggle="modal"
                                                                (click)="viewSavings(ol.OpportunityId)"
                                                                data-target="#savinggraphmodal"><img
                                                                    tooltip="Savings Graph"
                                                                    src="../../assets/images/graph.svg"
                                                                    alt="Graph" /></a>
                                                            <a href="javascript:;" data-toggle="modal"
                                                                (click)="viewComment(ol.OpportunityId,ol.OpportunityName)"
                                                                data-target="#commentmodal"><img
                                                                    tooltip="Add Comment"
                                                                    src="../../assets/images/pop.png"
                                                                    alt="comment" /></a>
                                                        </div>
                                                        <div class="myw-aj-sv-chart my-2"
                                                            id="{{'Bulletsavings-' + oq.queueId + '-' + ol.OpportunityId}}"
                                                            style="width: 100%;">
                                                            <mri-bullet-chart3 *ngIf="ol.OpportunityId == opportunityId"
                                                                [chartIdSelector]="'Bulletsavings-' + oq.queueId + '-' + ol.OpportunityId" >
                                                            </mri-bullet-chart3>
                                                        </div>
                                                        <div class="bullet-graph-legend">
                                                            <div class="graph-legend violet">Planned</div>
                                                            <div class="graph-legend purple">Completed</div>
                                                        </div>
                                            
                                                        <!-- <div class="myw-aj-svHr-chart my-2"
                                                            id="{{'BulletsavingsHr-' + oq.queueId + '-' + ol.OpportunityId}}"
                                                            style="width: 100%;height:50px;text-align-last: center;">                                                            
                                                            <mri-bulletsavinghrs-chart-cmpSec
                                                                [chartIdSelector]="'BulletsavingsHr-' + oq.queueId + '-' + ol.OpportunityId">
                                                            </mri-bulletsavinghrs-chart-cmpSec>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 statusListPadding">
                                <div class="myw-aj-col-head d-flex align-items-center">
                                    <div>cancelled <span>{{oq.Cancelled.length}}</span></div>
                                    <a href="javascript:;" class="ml-auto"><img src="../../assets/images/sort.svg"
                                            alt="Sort" (click)="sortGroupedOpportunities(oq.queueId,'Cancelled')" /></a>
                                </div>
                                <div class="myw-aj-bdy-content" cdkDropList
                                    id="groupedCancelledListContainer-{{oq.queueId}}" [cdkDropListData]="oq.Cancelled"
                                    (cdkDropListDropped)="dragAndDropGroupedOpportunity($event)">
                                    <div *ngFor="let ol of oq.Cancelled" id="{{ol.OpportunityId}}" cdkDrag>
                                        <div class="card" style="margin-bottom: 7px;">
                                            <div class="card-inner">
                                                <div class="card-header d-flex">
                                                    <div *ngIf="ol.Towername!=null; else towernull" tooltip="{{ol.OpportunityName}}-{{ol.Towername}}">
                                                        {{ (ol.OpportunityName.length > 45 ) ? (ol.OpportunityName |
                                                        slice:0:45) + '...' : ol.OpportunityName }}
                                                    </div>
                                                    <ng-template #towernull>
                                                    <div tooltip="{{ol.OpportunityName}}">
                                                        {{ (ol.OpportunityName.length > 45 ) ? (ol.OpportunityName |
                                                        slice:0:45) + '...' : ol.OpportunityName }}
                                                    </div>
                                                    </ng-template>
                                                    <!-- <a href="javascript:;" class="ml-auto"><img
                                                            src="../../assets/images/graph.svg" alt="" /></a> -->
                                                </div>
                                                <div class="card-body">
                                                    <div class="myw-aj-pas d-flex">
                                                        <div class="myw-aj-pas-item-container">
                                                            <div class="myw-aj-pas-item">
                                                                <div *ngIf="ol.PlannedStartDate != null">Planned Savings
                                                                    ($) : <span style="margin-left: 48px ;">{{ol.currentPlan != 0 ? ((ol.currentPlan >= 1000 ? ol.currentPlan/1000 : ol.currentPlan) | number : '1.2-2') : '0.00'}} {{ol.currentPlan >= 1000 ? 'k' : ''}}</span>
                                                                </div>
                                                                <div *ngIf="ol.PlannedStartDate == null">Planned Savings
                                                                    ($) : <span style="margin-left: 48px ;">0.00</span> </div>
                                                            </div>
                                                        </div>
                                                        <div class="myw-aj-cmore">
                                                            <a class="collapsed" data-toggle="collapse"
                                                                href="#{{'cancelledCollapse' + ol.OpportunityId}}"
                                                                role="button" aria-expanded="false"><img
                                                                    src="../../assets/images/arrow-down.svg"
                                                                    alt="" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="collapse" id="{{'cancelledCollapse' + ol.OpportunityId}}">
                                                <div class="myw-aj-asset-container">
                                                    <div class="myw-aj-asset-list d-flex"
                                                        *ngFor="let asset of ol.Assets">
                                                        <div class="dropdown dropcenter"
                                                            *ngIf="asset.OrderStatus == 'Backlog'">
                                                            <a class="dropdown-toggle critical" type="button"
                                                                href="{{'Backlog' + asset.AssetId}}"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">&nbsp;</a>
                                                            <!-- <div class="dropdown-menu mt-2"
                                                                id="{{'Backlog' + asset.AssetId}}">
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'Cancel', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'InProgress',ol.PlannedStartDate)"><span
                                                                        class="medium">&nbsp;</span></a>
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'Cancel', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete',ol.PlannedStartDate)"><span
                                                                        class="low">&nbsp;</span></a>
                                                            </div> -->
                                                        </div>
                                                        <div class="myw-aj-asset"
                                                            *ngIf="asset.OrderStatus == 'Backlog'">
                                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                                            <span class="ml-1">{{asset.AssetName}}</span>
                                                        </div>

                                                        <div class="dropdown dropcenter"
                                                            *ngIf="asset.OrderStatus == 'InProgress'">
                                                            <a class="dropdown-toggle medium" type="button"
                                                                href="{{'InProgress' + asset.AssetId}}"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">&nbsp;</a>
                                                            <!-- <div class="dropdown-menu mt-2"
                                                                id="{{'InProgress' + asset.AssetId}}">
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'Cancel', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Backlog',ol.PlannedStartDate)"><span
                                                                        class="critical">&nbsp;</span></a>
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'Cancel', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete',ol.PlannedStartDate)"><span
                                                                        class="low">&nbsp;</span></a>
                                                            </div> -->
                                                        </div>
                                                        <div class="myw-aj-asset"
                                                            *ngIf="asset.OrderStatus == 'InProgress'">
                                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                                            <span class="ml-1">{{asset.AssetName}}</span>
                                                        </div>

                                                        <div class="dropdown dropcenter"
                                                            *ngIf="asset.OrderStatus == 'Complete'">
                                                            <a class="dropdown-toggle low" type="button"
                                                                href="{{'Complete' + asset.AssetId}}"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">&nbsp;</a>
                                                            <!-- <div class="dropdown-menu mt-2"
                                                                id="{{'Complete' + asset.AssetId}}">
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'Cancel', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Backlog',ol.PlannedStartDate)"><span
                                                                        class="critical">&nbsp;</span></a>
                                                                <a class="dropdown-item"
                                                                    (click)="changeAssetStatus(oq.queueId,'Cancel', ol.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'InProgress',ol.PlannedStartDate)"><span
                                                                        class="medium">&nbsp;</span></a>
                                                            </div> -->
                                                        </div>
                                                        <div class="myw-aj-asset"
                                                            *ngIf="asset.OrderStatus == 'Complete'">
                                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                                            <span class="ml-1">{{asset.AssetName}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Accordion (group queues)-->

        <!-- No accordion (no grouping by queues)-->
        <div *ngIf="!grouping" class="myw-aj-bdy-container myw-scrollbar">
            <div class="row" cdkDropListGroup>
                <div class="col-3 statusListPadding">
                    <div class="myw-aj-col-head d-flex align-items-center">
                        <div>Backlog <span>{{backlogList.length}}</span></div>
                        <a (click)="sortUnGroupedOpportunities('Backlog')" href="javascript:;" class="ml-auto mr-2"><img
                                src="../../assets/images/sort.svg" alt="Sort" /></a>
                        <a (click)="APHome()" target="blank" rel="noopener noreferrer"><img src="../../assets/images/plus.svg" alt="" /></a>
                    </div>
                    <div id="backlogListContainer" cdkDropList [cdkDropListData]="backlogList"
                        (cdkDropListDropped)="dragAndDropUnGroupedOpportunity($event)" class="myw-aj-bdy-content">
                        <div id="{{bll.OpportunityId}}" class="card" *ngFor="let bll of backlogList" cdkDrag>
                            <div class="card-inner">
                                <div class="card-header">
                                    <div *ngIf="bll.Towername!=null; else towernull" tooltip="{{bll.OpportunityName}}-{{bll.Towername}}">
                                        {{ (bll.OpportunityName.length > 45 ) ? (bll.OpportunityName | slice:0:45) +
                                            '...' : bll.OpportunityName }}
                                    </div>
                                    <ng-template #towernull>
                                        <div tooltip="{{bll.OpportunityName}}">
                                            {{ (bll.OpportunityName.length > 45 ) ? (bll.OpportunityName | slice:0:45) +
                                                '...' : bll.OpportunityName }}
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="card-body">
                                    <div class="myw-aj-pas d-flex">
                                        <div class="myw-aj-pas-item">
                                            <div *ngIf="bll.PlannedStartDate != null">Planned Savings ($) :
                                                {{bll.currentPlan != 0 ? (bll.currentPlan >= 1000
                                                ? bll.currentPlan/1000 + 'k' : bll.currentPlan):'0.00'}}</div>
                                            <div *ngIf="bll.PlannedStartDate == null">Planned Savings ($) : <span style="margin-left: 48px ;">0.00</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 statusListPadding">
                    <div class="myw-aj-col-head d-flex align-items-center">
                        <div>In progress <span>{{inProgressList.length}}</span></div>
                        <a (click)="sortUnGroupedOpportunities('InProgress')" href="javascript:;" class="ml-auto"><img
                                src="../../assets/images/sort.svg" alt="Sort" /></a>
                    </div>
                    <div id="inProgressListContainer" cdkDropList [cdkDropListData]="inProgressList"
                        (cdkDropListDropped)="dragAndDropUnGroupedOpportunity($event)" class="myw-aj-bdy-content">
                        <div id="{{ipl.OpportunityId}}" class="card" *ngFor="let ipl of inProgressList" cdkDrag>
                            <div class="card-inner">
                                <div class="card-header d-flex">
                                    <!-- <div>{{ipl.OpportunityName}}</div> -->
                                    <div *ngIf="ipl.Towername!=null; else towernull" tooltip="{{ipl.OpportunityName}}-{{ipl.Towername}}">
                                        {{ (ipl.OpportunityName.length > 45 ) ? (ipl.OpportunityName | slice:0:45) +
                                            '...' : ipl.OpportunityName }}
                                    </div>
                                    <ng-template #towernull>
                                    <div tooltip="{{ipl.OpportunityName}}">
                                        {{ (ipl.OpportunityName.length > 45 ) ? (ipl.OpportunityName | slice:0:45) +
                                            '...' : ipl.OpportunityName }}
                                    </div>
                                    </ng-template>
                                    <a target="blank" rel="noopener noreferrer" class="ml-auto" style="cursor: pointer;"
                                        (click)="ApRedirect(ipl.BluePrintId,ipl.AppName,ipl.Type,ipl.DeepDiveId,ipl.CategoryId,ipl.SubCategoryId,ipl.cmsUID,ipl.wsUId)">
                                        <img src="../../assets/images/edit.svg" alt="" />
                                    </a>
                                </div>
                                <div class="card-body">
                                    <div class="myw-aj-pas d-flex">
                                        <div class="myw-aj-pas-item-container">
                                            <div class="myw-aj-pas-item">
                                                <div *ngIf="ipl.PlannedStartDate != null">Planned Savings ($) :
                                                    <span>{{ipl.currentPlan != 0 ? (ipl.currentPlan >=
                                                        1000 ? ipl.currentPlan/1000 + 'k' :
                                                        ipl.currentPlan):'0.00'}}</span>
                                                </div>
                                                <div *ngIf="ipl.PlannedStartDate == null">Planned Savings ($) : <span style="margin-left: 48px ;">0.00</span>
                                                </div>
                                            </div>
                                            <div class="myw-aj-pas-item">
                                                <div>Benefit Realization Date : <div
                                                        style="width: 39%;float:right;margin: -8% 2%; font-family: sans-serif">
                                                        <ejs-datepicker class="datepicker" format='MM/dd/yyyy'
                                                            [allowEdit]=false placeholder='Select a date'
                                                            [(ngModel)]="ipl.PlannedStartDate"
                                                            (change)="UpdatePlannedDate(ipl.OpportunityId,$event)">
                                                        </ejs-datepicker>
                                                    </div>
                                                </div>
                                                <div style="color: red;" *ngIf="ipl.PlannedStartDate == undefined">
                                                    Please select a benefit realization date.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="myw-aj-cmore">
                                            <a class="collapsed" data-toggle="collapse"
                                                href="#{{'inProgressCollapse' + ipl.OpportunityId}}" role="button"
                                                aria-expanded="false"><img src="../../assets/images/arrow-down.svg"
                                                    alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" id="{{'inProgressCollapse' + ipl.OpportunityId}}">
                                <div class="myw-aj-asset-container">
                                    <div class="myw-aj-asset-list d-flex" *ngFor="let asset of ipl.Assets">
                                        <div class="dropdown dropcenter" *ngIf="asset.OrderStatus == 'Backlog'">
                                            <a class="dropdown-toggle critical" type="button"
                                                href="{{'Backlog' + asset.AssetId}}" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">&nbsp;</a>
                                            <div class="dropdown-menu mt-2" id="{{'Backlog' + asset.AssetId}}">
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-12345678,'InProgress', ipl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'InProgress',ipl.PlannedStartDate)"><span
                                                        class="medium">&nbsp;</span></a>
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-12345678,'InProgress', ipl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete',ipl.PlannedStartDate)"><span
                                                        class="low">&nbsp;</span></a>
                                            </div>
                                        </div>
                                        <div class="myw-aj-asset" *ngIf="asset.OrderStatus == 'Backlog'">
                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                            <span class="ml-1">{{asset.AssetName}}</span>
                                        </div>

                                        <div class="dropdown dropcenter" *ngIf="asset.OrderStatus == 'InProgress'">
                                            <a class="dropdown-toggle medium" type="button"
                                                href="{{'InProgress' + asset.AssetId}}" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">&nbsp;</a>
                                            <div class="dropdown-menu mt-2" id="{{'InProgress' + asset.AssetId}}">
                                                <!-- <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-1234567,'InProgress', ipl.OpportunityId, asset.AssetId, asset.AssetName, 'Backlog')"><span
                                                        class="critical">&nbsp;</span></a> -->
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-1234567,'InProgress', ipl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete',ipl.PlannedStartDate)"><span
                                                        class="low">&nbsp;</span></a>
                                            </div>
                                        </div>
                                        <div class="myw-aj-asset" *ngIf="asset.OrderStatus == 'InProgress'">
                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                            <span class="ml-1">{{asset.AssetName}}</span>
                                        </div>

                                        <div class="dropdown dropcenter" *ngIf="asset.OrderStatus == 'Complete'">
                                            <a class="dropdown-toggle low" type="button"
                                                href="{{'Complete' + asset.AssetId}}" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">&nbsp;</a>
                                            <!-- <div class="dropdown-menu mt-2" id="{{'Complete' + asset.AssetId}}">
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-1234567,'InProgress', ipl.OpportunityId, asset.AssetId, asset.AssetName, 'Backlog')"><span
                                                        class="critical">&nbsp;</span></a>
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-1234567,'InProgress', ipl.OpportunityId, asset.AssetId, asset.AssetName, 'InProgress')"><span
                                                        class="medium">&nbsp;</span></a>
                                            </div> -->
                                        </div>
                                        <div class="myw-aj-asset" *ngIf="asset.OrderStatus == 'Complete'">
                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                            <span class="ml-1">{{asset.AssetName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 statusListPadding">
                    <div class="myw-aj-col-head d-flex align-items-center">
                        <div>Completed <span>{{completedList.length}}</span></div>
                        <a (click)="sortUnGroupedOpportunities('Complete')" href="javascript:;" class="ml-auto"><img
                                src="../../assets/images/sort.svg" alt="Sort" /></a>
                    </div>
                    <div id="completedListContainer" cdkDropList [cdkDropListData]="completedList"
                        (cdkDropListDropped)="dragAndDropUnGroupedOpportunity($event)" class="myw-aj-bdy-content">
                        <div id="{{cpl.OpportunityId}}" class="card" *ngFor="let cpl of completedList" cdkDrag>
                            <div class="card-inner">
                                <div class="card-header d-flex">
                                    <!-- <div>{{cpl.OpportunityName}}</div> -->
                                    <div *ngIf="cpl.Towername!=null; else towernull" tooltip="{{cpl.OpportunityName}}-{{cpl.Towername}}">
                                        {{ (cpl.OpportunityName.length > 45 ) ? (cpl.OpportunityName | slice:0:45) +
                                            '...' : cpl.OpportunityName }}
                                    </div>
                                    <ng-template #towernull>
                                        <div tooltip="{{cpl.OpportunityName}}">
                                            {{ (cpl.OpportunityName.length > 45 ) ? (cpl.OpportunityName | slice:0:45) +
                                                '...' : cpl.OpportunityName }}
                                        </div>
                                    </ng-template>
                                    <a href="javascript:;" class="ml-auto" data-toggle="modal"
                                        (click)="viewROI(cpl.OpportunityId)" data-target="#roiGraphmodal"><img
                                            src="../../assets/images/graph.svg" alt="" tooltip="ROI Graph" /></a>
                                         
                                </div>
                                <div class="card-body">
                                    <div class="myw-aj-pas d-flex">
                                        <div class="myw-aj-pas-item-container">
                                            <div class="myw-aj-pas-item">
                                                <div *ngIf="cpl.PlannedStartDate != null">Planned Savings ($) : <span
                                                        class="plannedAndActual">{{cpl.currentPlan != 0 ?
                                                        (cpl.currentPlan >= 1000 ? cpl.currentPlan/1000 + 'k' :
                                                        cpl.currentPlan):'0.00'}}</span></div>
                                                <div *ngIf="cpl.PlannedStartDate == null">Planned Savings ($) : <span style="margin-left: 48px ;">0.00</span>                                                </div>
                                            </div>
                                            <div style="margin: 10px;"></div>
                                            <div>
                                                Actual Savings ($) &nbsp;&nbsp;&nbsp;: <span
                                                    class="plannedAndActual">{{cpl.actualSavings != 0 ?
                                                    (cpl.actualSavings >= 1000 ? cpl.actualSavings/1000 + 'k' :
                                                    cpl.actualSavings) : '0.00'}}</span>
                                            </div>
                                            <div style="margin: 10px;"></div>
                                            <div class="myw-aj-pas-item">
                                                <div>Benefit Realization Date : <div
                                                        style="width: 39%;float:right;margin: -8% 2%; font-family: sans-serif">
                                                        <ejs-datepicker class="datepicker" format='MM/dd/yyyy'
                                                            [allowEdit]=false placeholder='Select a date'
                                                            [(ngModel)]="cpl.PlannedStartDate"
                                                            (change)="UpdatePlannedDate(cpl.OpportunityId,$event)">
                                                        </ejs-datepicker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="myw-aj-cmore">
                                            
                                                    <div class="myw-aj-cmore">
                                                        <a class="collapsed" data-toggle="collapse"
                                                            id="{{'toggleCompletedCollapse' + cpl.OpportunityId}}"
                                                            href="#{{'completedCollapse' + cpl.OpportunityId}}"
                                                            (click)="openAssetDataAndCharts(cpl.OpportunityId, cpl.PlannedStartDate)"
                                                            role="button" aria-expanded="false"><img
                                                                src="../../assets/images/arrow-down.svg"
                                                                alt="" /></a>
                                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" id="{{'completedCollapse' + cpl.OpportunityId}}"
                            *ngIf="cpl.OpportunityId == opportunityId">
                                <div class="myw-aj-asset-container">
                                    <div class="myw-aj-asset-list d-flex" *ngFor="let asset of cpl.Assets">
                                        <div class="dropdown dropcenter" *ngIf="asset.OrderStatus == 'Backlog'">
                                            <a class="dropdown-toggle critical" type="button"
                                                href="{{'Backlog' + asset.AssetId}}" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">&nbsp;</a>
                                            <div class="dropdown-menu mt-2" id="{{'Backlog' + asset.AssetId}}">
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-1234567,'Complete', cpl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'InProgress',cpl.PlannedStartDate)"><span
                                                        class="medium">&nbsp;</span></a>
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-1234567,'Complete', cpl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete',cpl.PlannedStartDate)"><span
                                                        class="low">&nbsp;</span></a>
                                            </div>
                                        </div>
                                        <div class="myw-aj-asset" *ngIf="asset.OrderStatus == 'Backlog'">
                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                            <span class="ml-1">{{asset.AssetName}}</span>
                                        </div>

                                        <div class="dropdown dropcenter" *ngIf="asset.OrderStatus == 'InProgress'">
                                            <a class="dropdown-toggle medium" type="button"
                                                href="{{'InProgress' + asset.AssetId}}" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">&nbsp;</a>
                                            <div class="dropdown-menu mt-2" id="{{'InProgress' + asset.AssetId}}">
                                                <!-- <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-1234567,'Complete', cpl.OpportunityId, asset.AssetId, asset.AssetName, 'Backlog')"><span
                                                        class="critical">&nbsp;</span></a> -->
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-1234567,'Complete', cpl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete',cpl.PlannedStartDate)"><span
                                                        class="low">&nbsp;</span></a>
                                            </div>
                                        </div>
                                        <div class="myw-aj-asset" *ngIf="asset.OrderStatus == 'InProgress'">
                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                            <span class="ml-1">{{asset.AssetName}}</span>
                                        </div>

                                        <div class="dropdown dropcenter" *ngIf="asset.OrderStatus == 'Complete'">
                                            <a class="dropdown-toggle low" type="button"
                                                href="{{'Complete' + asset.AssetId}}" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">&nbsp;</a>
                                            <!-- <div class="dropdown-menu mt-2" id="{{'Complete' + asset.AssetId}}">
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-1234567,'Complete', cpl.OpportunityId, asset.AssetId, asset.AssetName, 'Backlog')"><span
                                                        class="critical">&nbsp;</span></a>
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-1234567,'Complete', cpl.OpportunityId, asset.AssetId, asset.AssetName, 'InProgress')"><span
                                                        class="medium">&nbsp;</span></a>
                                            </div> -->
                                        </div>
                                        <div class="myw-aj-asset" *ngIf="asset.OrderStatus == 'Complete'">
                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                            <span class="ml-1">{{asset.AssetName}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-inner">
                                    <div class="myw-aj-tile my-2">
                                        <div class="myw-aj-thead d-flex">
                                            <div>Ticket Volume</div>
                                            <a href="javascript:;" data-toggle="modal" data-target="#ticketmodal"
                                                (click)="editTicketVolume(-1, cpl.OpportunityId, cpl.OpportunityName, cpl.PlannedStartDate)"
                                                class="ml-auto"><img src="../../assets/images/edit.svg"
                                                    (click)="viewTicketVolume(cpl.OpportunityId)" alt="Edit" /></a>
                                            <a href="javascript:;" data-toggle="modal"
                                                data-target="#ticketVolmodal"><img src="../../assets/images/graph.svg"
                                                    tooltip="Ticket Volume Graph" alt="Graph" /></a>
                                        </div>
                                        <div class="myw-aj-tv-chart my-2"
                                            id="{{'TicketVolDonutChart-' + cpl.OpportunityId}}" style="width: 100%;">
                                            <app-donut-chart-ticket *ngIf="cpl.OpportunityId == opportunityId"
                                                [chartIdSelector]="'TicketVolDonutChart-' + cpl.OpportunityId">
                                            </app-donut-chart-ticket>
                                        </div>
                                    </div>
                                    <div class="myw-aj-tile my-2">
                                        <div class="myw-aj-thead d-flex" style="margin-top: 20px;">
                                            <div>Savings ($)</div>
                                            <a href="javascript:;" data-toggle="modal" data-target="#savingmodal"
                                                (click)="editSavings(-1, cpl.OpportunityId, cpl.OpportunityName, cpl.PlannedStartDate)"
                                                class="ml-auto mr-2"><img src="../../assets/images/edit.svg"
                                                    alt="Edit" /></a>
                                            <a href="javascript:;" data-toggle="modal"
                                                (click)="viewSavings(cpl.OpportunityId)"
                                                data-target="#savinggraphmodal"><img src="../../assets/images/graph.svg"
                                                    tooltip="Savings Graph" alt="Graph" /></a>
                                            <a href="javascript:;" data-toggle="modal"
                                                (click)="viewComment(cpl.OpportunityId, cpl.OpportunityName)"
                                                data-target="#commentmodal"><img
                                                    tooltip="Add Comment" src="../../assets/images/pop.png" alt="Comment" /></a>
                                        </div>
                                        <div class="myw-aj-sv-chart my-2" id="{{'Bulletsavings-' + cpl.OpportunityId}}"
                                            style="width: 100%;text-align-last: center;">
                                            <mri-bullet-chart3 *ngIf="cpl.OpportunityId == opportunityId"
                                                [chartIdSelector]="'Bulletsavings-' + cpl.OpportunityId">
                                            </mri-bullet-chart3>
                                        </div>
                                        <div class="bullet-graph-legend">
                                            <div class="graph-legend violet">Planned</div>
                                            <div class="graph-legend purple">Completed</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 statusListPadding">
                    <div class="myw-aj-col-head d-flex align-items-center">
                        <div>cancelled <span>{{cancelledList.length}}</span></div>
                        <a (click)="sortUnGroupedOpportunities('cancelled')" href="javascript:;" class="ml-auto"><img
                                src="../../assets/images/sort.svg" alt="Sort" /></a>
                    </div>
                    <div id="cancelledListContainer" cdkDropList [cdkDropListData]="cancelledList"
                        (cdkDropListDropped)="dragAndDropUnGroupedOpportunity($event)" class="myw-aj-bdy-content">
                        <div id="{{ccl.OpportunityId}}" class="card" *ngFor="let ccl of cancelledList" cdkDrag>
                            <div class="card-inner">
                                <div class="card-header d-flex">
                                    <div *ngIf="ccl.Towername!=null; else towernull" tooltip="{{ccl.OpportunityName}}-{{ccl.Towername}}">
                                        {{ (ccl.OpportunityName.length > 45 ) ? (ccl.OpportunityName | slice:0:45) +
                                            '...' : ccl.OpportunityName }}
                                    </div>
                                    <ng-template #towernull>
                                        <div tooltip="{{ccl.OpportunityName}}">
                                            {{ (ccl.OpportunityName.length > 45 ) ? (ccl.OpportunityName | slice:0:45) +
                                                '...' : ccl.OpportunityName }}
                                        </div>
                                    </ng-template>
                                    <!-- <a href="javascript:;" class="ml-auto"><img src="../../assets/images/graph.svg"
                                            alt="" /></a> -->
                                </div>
                                <div class="card-body">
                                    <div class="myw-aj-pas d-flex">
                                        <div class="myw-aj-pas-item-container">
                                            <div class="myw-aj-pas-item">
                                                <div *ngIf="ccl.PlannedStartDate != null">Planned Savings ($) :
                                                    <span style="margin-left: 48px ;">{{ccl.currentPlan != 0 ? (ccl.currentPlan >=
                                                        1000 ? ccl.currentPlan/1000 + 'k' :
                                                        ccl.currentPlan):'0.00'}}</span>
                                                </div>
                                                <div *ngIf="ccl.PlannedStartDate == null">Planned Savings ($) : <span style="margin-left: 48px ;">0.00</span></div>
                                            </div>
                                        </div>
                                        <div class="myw-aj-cmore">
                                            <a class="collapsed" data-toggle="collapse"
                                                href="#{{'cancelledCollapse' + ccl.OpportunityId}}" role="button"
                                                aria-expanded="false"><img src="../../assets/images/arrow-down.svg"
                                                    alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" id="{{'cancelledCollapse' + ccl.OpportunityId}}">
                                <div class="myw-aj-asset-container">
                                    <div class="myw-aj-asset-list d-flex" *ngFor="let asset of ccl.Assets">
                                        <div class="dropdown dropcenter" *ngIf="asset.OrderStatus == 'Backlog'">
                                            <a class="dropdown-toggle critical" type="button"
                                                href="{{'Backlog' + asset.AssetId}}" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">&nbsp;</a>
                                            <!-- <div class="dropdown-menu mt-2" id="{{'Backlog' + asset.AssetId}}">
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-12345678,'Cancel', ccl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'InProgress',ccl.PlannedStartDate)"><span
                                                        class="medium">&nbsp;</span></a>
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-12345678,'Cancel', ccl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete',ccl.PlannedStartDate)"><span
                                                        class="low">&nbsp;</span></a>
                                            </div> -->
                                        </div>
                                        <div class="myw-aj-asset" *ngIf="asset.OrderStatus == 'Backlog'">
                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                            <span class="ml-1">{{asset.AssetName}}</span>
                                        </div>

                                        <div class="dropdown dropcenter" *ngIf="asset.OrderStatus == 'InProgress'">
                                            <a class="dropdown-toggle medium" type="button"
                                                href="{{'InProgress' + asset.AssetId}}" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">&nbsp;</a>
                                            <!-- <div class="dropdown-menu mt-2" id="{{'InProgress' + asset.AssetId}}">
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-12345678,'Cancel', ccl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Backlog',ccl.PlannedStartDate)"><span
                                                        class="critical">&nbsp;</span></a>
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-12345678,'Cancel', ccl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Complete',ccl.PlannedStartDate)"><span
                                                        class="low">&nbsp;</span></a>
                                            </div> -->
                                        </div>
                                        <div class="myw-aj-asset" *ngIf="asset.OrderStatus == 'InProgress'">
                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                            <span class="ml-1">{{asset.AssetName}}</span>
                                        </div>

                                        <div class="dropdown dropcenter" *ngIf="asset.OrderStatus == 'Complete'">
                                            <a class="dropdown-toggle low" type="button"
                                                href="{{'Complete' + asset.AssetId}}" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">&nbsp;</a>
                                            <!-- <div class="dropdown-menu mt-2" id="{{'Complete' + asset.AssetId}}">
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-12345678,'Cancel', ccl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'Backlog',ccl.PlannedStartDate)"><span
                                                        class="critical">&nbsp;</span></a>
                                                <a class="dropdown-item"
                                                    (click)="changeAssetStatus(-12345678,'Cancel', ccl.OpportunityId, asset.AssetId, asset.AssetName, asset.AssetType, 'InProgress',ccl.PlannedStartDate)"><span
                                                        class="medium">&nbsp;</span></a>
                                            </div> -->
                                        </div>
                                        <div class="myw-aj-asset" *ngIf="asset.OrderStatus == 'Complete'">
                                            <img *ngIf ="asset.AssetType == 'App'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'Integrator'" src="assets/images/aatr.svg" alt="" />

                                            <img *ngIf ="asset.AssetType == 'BOT'" src="assets/images/bot.svg" alt="" />                                            <span class="ml-1">{{asset.AssetName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /No accordion (no grouping queues)-->
    </div>
</div>
<!-- /content -->

<!-- ROI Graph Modal -->
<div class="modal" id="roiGraphmodal" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="roiGraphmodalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content" style="width: 1000px; height: 380px">
            <div class="modal-header">
                <h5 class="modal-title" id="roiGraphmodalTitle">ROI Graph</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&nbsp;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="myw-aj-roi-graph" width="100%">
                    <mri-multiline-top-trending-assets [opportunityId]="opportunityId" [currentDate]="currentDate"
                        [monthsList]="monthsList" [yearsList]="yearsList"></mri-multiline-top-trending-assets>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Ticket Volume Graph Modal -->
<div class="modal" id="ticketVolmodal" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="ticketVolmodalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content" style="width: 1200px; height: 430px">
            <div class="modal-header">
                <h5 class="modal-title" id="ticketVolmodalTitle">Ticket Volume Graph</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&nbsp;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="myw-aj-tv-graph">
                    <mri-multiline-tickvol-trending-assets [opportunityId]="opportunityId" [currentDate]="currentDate"
                        [monthsList]="monthsList" [yearsList]="yearsList"></mri-multiline-tickvol-trending-assets>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Saving Graph Modal -->
<div class="modal" id="savinggraphmodal" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="savinggraphmodalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="savinggraphmodalTitle">Savings &amp; FTE Graph</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&nbsp;</span>
                </button>
            </div>
            <div class="modal-body pt-1">
                <nav class="nav myw-nav" id="myTab" role="tablist">
                    <a class="nav-item nav-link active" id="saving-tab" data-toggle="tab" href="#saving" role="tab"
                        aria-controls="saving" aria-selected="true">Saving</a>
                    <a class="nav-item nav-link" id="fte-tab" data-toggle="tab" href="#fte" role="tab"
                        aria-controls="fte" aria-selected="true">FTE</a>
                </nav>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="saving" role="tabpanel" aria-labelledby="saving-tab">
                        <div class="myw-aj-sv-graph mt-3" style="width: 100%;">
                            <app-standard-line-chart [opportunityId]="opportunityId" [currentDate]="currentDate"
                                [monthsList]="monthsList" [yearsList]="yearsList"></app-standard-line-chart>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="fte" role="tabpanel" aria-labelledby="fte-tab">
                        <div class="myw-aj-sv-graph mt-3">
                            <fte-line-chart [opportunityId]="opportunityId" [currentDate]="currentDate"
                                [monthsList]="monthsList" [yearsList]="yearsList"></fte-line-chart>
                        </div>
                    </div>
                </div>

            </div>
            <!-- <div class="modal-body pt-1">
                <nav class="nav myw-nav">
                    <a href="javascript:;" class="nav-item nav-link active">Saving</a>
                    <a href="javascript:;" class="nav-item nav-link">FTE</a>
                </nav>
                <div class="myw-aj-sv-graph mt-3" id="savingGraph">Chart</div>
            </div> -->
        </div>
    </div>
</div>

<!-- Ticket Modal -->
<div class="modal" id="ticketmodal" *ngIf="displayPopup == 'ticketVolume'" data-backdrop="static" data-keyboard="false"
    tabindex="-1" aria-labelledby="ticketTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xlarge">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ticketTitle">Ticket Volume</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="updateTicketVolumeChart()">
                    <span aria-hidden="true">&nbsp;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="fs-16 mb-2">
                    {{opportunityName}}
                    <span id="infoMessage" style="color: red;"></span>
                </div>
                <div class="myw-section">
                    <div class="table-responsive">
                        <table class="table table-striped table-vmiddle myw-table myw-pu-table"
                            style="overflow-x: scroll;" *ngIf="moreThan12months">
                            <thead>
                                <tr>
                                    <th scope="col" width="10%">Months</th>
                                    <th scope="col" class="text-center" *ngFor="let i of noOfMonthsArray"
                                        style="width:70px; white-space: nowrap;">
                                        {{i}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="10%">Total Occurences</td>
                                    <td align="center" *ngFor="let i of noOfMonthsArray">
                                        <input type="text" class="form-control" style="width:50px"
                                            (focusin)="savePreviousValue($event.target.value)"
                                            (focusout)="saveTicketVolume($event.target.id, $event.target.value, 5)"
                                            id="{{'totalOccurence-' + i.replace(' ','')}}"
                                            onkeydown="if(event.key==='.'){event.preventDefault();}"
                                            onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}" />
                                    </td>
                                </tr>
                                <!-- <div *ngFor="let asset of assetsList" style="width:355%"> -->
                                <!-- <tr>
                                        <td colspan="13">
                                            <div class=""><img src="../../assets/images/aatr.svg" alt="" /><span
                                                    class="myw-700 ml-2">{{asset.AssetName}}</span></div>
                                        </td>
                                    </tr> -->
                                <tr>
                                    <td width="10%">Automated Fully</td>
                                    <td align="center" *ngFor="let i of noOfMonthsArray">
                                        <input type="text" class="form-control" style="width:50px"
                                            (focusin)="savePreviousValue($event.target.value)"
                                            (focusout)="saveTicketVolume($event.target.id, $event.target.value, 1)"
                                            id="{{'automatedFully-' + i.replace(' ','')}}"
                                            onkeydown="if(event.key==='.'){event.preventDefault();}"
                                            onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}" />
                                    </td>
                                </tr>
                                <tr>
                                    <td width="10%">Automated Partially</td>
                                    <td align="center" *ngFor="let i of noOfMonthsArray">
                                        <input type="text" class="form-control" style="width:50px"
                                            (focusin)="savePreviousValue($event.target.value)"
                                            (focusout)="saveTicketVolume($event.target.id, $event.target.value, 2)"
                                            id="{{'automatedPartially-' + i.replace(' ','')}}"
                                            onkeydown="if(event.key==='.'){event.preventDefault();}"
                                            onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}" />
                                    </td>
                                </tr>
                                <!-- </div> -->
                            </tbody>
                        </table>

                        <table class="table table-striped table-vmiddle myw-table myw-pu-table"
                            *ngIf="!moreThan12months">
                            <thead>
                                <tr>
                                    <th scope="col" width="10%">Months</th>
                                    <th scope="col" class="text-center" *ngFor="let i of noOfMonthsArray">
                                        {{monthsList[currentDate.getMonth() + i] != undefined ?
                                        monthsList[currentDate.getMonth() + i] :
                                        monthsList[currentDate.getMonth() + i - 12]}}
                                        {{monthsList[currentDate.getMonth() + i] != undefined ?
                                        yearsList[0] : yearsList[1]}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="16%">Total Occurences</td>
                                    <td align="center" *ngFor="let i of noOfMonthsArray">
                                        <input type="text" class="form-control"
                                            (focusin)="savePreviousValue($event.target.value)"
                                            (focusout)="saveTicketVolume($event.target.id, $event.target.value, 5)"
                                            onkeydown="if(event.key==='.'){event.preventDefault();}"
                                            onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                            id="{{'totalOccurence-' + (monthsList[currentDate.getMonth() + i] != undefined ? monthsList[currentDate.getMonth() + i] + yearsList[0] : monthsList[currentDate.getMonth() + i - 12] + yearsList[1])}}" />
                                    </td>
                                </tr>
                                <!-- <div *ngFor="let asset of assetsList" style="width:355%"> -->
                                <!-- <tr>
                                        <td colspan="13">
                                            <div class=""><img src="../../assets/images/aatr.svg" alt="" /><span
                                                    class="myw-700 ml-2">{{asset.AssetName}}</span></div>
                                        </td>
                                    </tr> -->
                                <tr>
                                    <td width="16%">Automated Fully</td>
                                    <td align="center" *ngFor="let i of noOfMonthsArray">
                                        <input type="text" class="form-control"
                                            (focusin)="savePreviousValue($event.target.value)"
                                            (focusout)="saveTicketVolume($event.target.id, $event.target.value, 1)"
                                            onkeydown="if(event.key==='.'){event.preventDefault();}"
                                            onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                            id="{{'automatedFully-' + (monthsList[currentDate.getMonth() + i] != undefined ? monthsList[currentDate.getMonth() + i] + yearsList[0] : monthsList[currentDate.getMonth() + i - 12] + yearsList[1])}}" />
                                    </td>
                                </tr>
                                <tr>
                                    <td width="16%">Automated Partially</td>
                                    <td align="center" *ngFor="let i of noOfMonthsArray">
                                        <input type="text" class="form-control"
                                            (focusin)="savePreviousValue($event.target.value)"
                                            (focusout)="saveTicketVolume($event.target.id, $event.target.value, 2)"
                                            onkeydown="if(event.key==='.'){event.preventDefault();}"
                                            onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                            id="{{'automatedPartially-' + (monthsList[currentDate.getMonth() + i] != undefined ? monthsList[currentDate.getMonth() + i] + yearsList[0] : monthsList[currentDate.getMonth() + i - 12] + yearsList[1])}}" />
                                    </td>
                                </tr>
                                <!-- </div> -->
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- <div class="d-flex align-items-center justify-content-end myw-actions mt-4">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                        (click)="saveTicketVolume()">Save</button>
                </div> -->
            </div>
        </div>
    </div>
</div>

<!-- Saving Modal -->
<div class="modal" id="savingmodal" *ngIf="displayPopup == 'savingsAndFTE'" data-backdrop="static" data-keyboard="false"
    tabindex="-1" aria-labelledby="savingmodalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xlarge">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="savingmodalTitle">Savings</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="updateSavingsFTEChart()">
                    <span aria-hidden="true">&nbsp;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="fs-16 mb-2">{{opportunityName}}</div>
                <div class="myw-section">
                    <div class="table-responsive">
                        <table class="table table-striped table-vmiddle myw-table myw-pu-table myw-save-table"
                            style="overflow-x: scroll;" *ngIf="moreThan12months">
                            <thead>
                                <tr>
                                    <th scope="col" width="10%">Months</th>
                                    <th scope="col" class="text-center" *ngFor="let i of noOfMonthsArray"
                                        style="width:70px; white-space: nowrap;">
                                        {{i}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Value($)</td>
                                    <td align="center" *ngFor="let i of noOfMonthsArray">
                                        <input type="text" class="form-control savingsvalue" style="width: 50px;"
                                            (focusout)="saveSavings('Values ($k)', $event.target.id, $event.target.value, 3);"
                                            id="{{'value-' + i.replace(' ','')}}" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>FTE</td>
                                    <td align="center" *ngFor="let i of noOfMonthsArray">
                                        <input type="text" class="form-control" style="width: 50px;"
                                            (focusout)="saveSavings('FTE',$event.target.id, $event.target.value, 4)"
                                            id="{{'fte-' + i.replace(' ','')}}" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="table table-striped table-vmiddle myw-table myw-pu-table myw-save-table"
                            *ngIf="!moreThan12months">
                            <thead>
                                <tr>
                                    <th scope="col" width="20%">Months</th>
                                    <th scope="col" class="text-center" *ngFor="let i of noOfMonthsArray">
                                        {{monthsList[currentDate.getMonth() + i] != undefined ?
                                        monthsList[currentDate.getMonth() + i] :
                                        monthsList[currentDate.getMonth() + i - 12]}}
                                        {{monthsList[currentDate.getMonth() + i] != undefined ?
                                        yearsList[0] : yearsList[1]}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Value($)</td>
                                    <td align="center" *ngFor="let i of noOfMonthsArray">
                                        <input type="text" class="form-control savingsvalue"
                                            (focusout)="saveSavings('Values ($k)', $event.target.id, $event.target.value, 3);"
                                            id="{{'value-' + (monthsList[currentDate.getMonth() + i] != undefined ? monthsList[currentDate.getMonth() + i] + yearsList[0] : monthsList[currentDate.getMonth() + i - 12] + yearsList[1])}}" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>FTE</td>
                                    <td align="center" *ngFor="let i of noOfMonthsArray">
                                        <input type="text" class="form-control"
                                            (focusout)="saveSavings('FTE', $event.target.id, $event.target.value, 4)"
                                            id="{{'fte-' + (monthsList[currentDate.getMonth() + i] != undefined ? monthsList[currentDate.getMonth() + i] + yearsList[0] : monthsList[currentDate.getMonth() + i - 12] + yearsList[1])}}" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- <div class="d-flex align-items-center justify-content-end myw-actions mt-4">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                        (click)="saveSavings()">Save</button>
                </div> -->
            </div>
        </div>
    </div>
</div>

<!-- Usecase Modal -->
<div class="modal" id="usecasemodal" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="usecasemodalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content" style="width:1000px">
            <div class="modal-header">
                <h5 class="modal-title" id="usecasemodalTitle">Use Cases Implemented</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="myw-aj-uc-graph" style="width:100%;height:450px">
                    <mri-cluster-bar-chart-two> </mri-cluster-bar-chart-two>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Ticket Distribution Modal -->
<div class="modal" id="ticketdismodal" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="ticketdismodalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content" style="width:830px">
            <div class="modal-header">
                <h5 class="modal-title" id="ticketdismodalTitle">Ticket Distribution</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="clearTicketDistChart()">
                    <span aria-hidden="true">&nbsp;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <div class="myw-aj-td-option d-flex">
                    <div class="mr-2"><button class="btn btn-sm btn-secondary active">All</button></div>
                    <div>
                        <div class="d-flex mb-2 myw-aj-td-actions">
                            <button class="btn btn-sm btn-secondary">Incident</button>
                            <button class="btn btn-sm btn-secondary">Service Req</button>
                            <button class="btn btn-sm btn-secondary">Tasks</button>
                        </div>
                        <div class="d-flex mb-2 myw-aj-td-actions">
                            <button class="btn btn-sm btn-secondary">Top 5 Inc</button>
                            <button class="btn btn-sm btn-secondary">Top 10 Inc</button>
                            <button class="btn btn-sm btn-secondary">Top 10 SR</button>
                            <button class="btn btn-sm btn-secondary">Top 10 Problems</button>
                            <button class="btn btn-sm btn-secondary">Top 5 App/Queue</button>
                        </div>
                        <div class="d-flex mb-2 myw-aj-td-actions">
                            <button class="btn btn-sm btn-secondary">Not Identified</button>
                            <button class="btn btn-sm btn-secondary">Not Started</button>
                            <button class="btn btn-sm btn-secondary">Work in Progress</button>
                            <button class="btn btn-sm btn-secondary">Completed</button>
                            <button class="btn btn-sm btn-secondary">Deferred / Rejected</button>
                        </div>
                    </div>
                </div> -->
                <div class="myw-aj-td-graph" style="height:350px">
                    <app-heat-map-chart-pop></app-heat-map-chart-pop>
                </div>
                <!-- <div class="myw-aj-td-graph" id="TktDistHeatMapPopUp" #TktDistHeatMapPopUp style="width:100%,height=350px">
                    <app-heat-map-chart-pop></app-heat-map-chart-pop>
                </div> -->
            </div>
        </div>
    </div>
</div>

<!-- Comment Modal -->
<div class="modal" id="commentmodal" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="commentmodalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="commentmodalTitle">Add comment for - "{{opportunityName}}"</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&nbsp;</span>
                </button>
            </div>
            <div class="modal-body pt-1">
                <app-comment-box>
                </app-comment-box>
            </div>
        </div>
    </div>
</div>
